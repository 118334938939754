import FieldContainer from '../components/fields/field-container.vue'
import { authComputed } from '@state/modules/auth'
import { useOnPortalEnabledModules } from '@data/form'

export default {
  components: { FieldContainer },
  props: {
    disabled: { type: Boolean, default: false },
    readOnly: { type: Boolean, default: false },
    useDefaultValue: { type: Boolean, default: false },
    mode: { type: String, required: true },
    field: { type: Object, required: true },
    sectionId: { type: String, required: false, default: undefined },
    value: {
      type: [Object, String, Array, Number, Boolean],
      default: undefined,
    },
    hideAction: { type: Boolean, default: false },
    applyFormRules: { type: Boolean, default: false },
    fieldRulesState: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  model: {
    event: 'update-value',
  },
  computed: {
    ...authComputed,
    currentValue: {
      get() {
        return this.value
      },
      set(newValue) {
        if (this.mode === 'builder') {
          return false
        }
        this.$emit('update-value', newValue)
      },
    },
    containerComponent() {
      if (this.mode === 'builder') {
        return FieldContainer
      }
      return 'FlotoFormItem'
    },
    listeners() {
      const { change, input, ...listeners } = this.$listeners
      if (this.mode === 'builder') {
        return listeners
      }
      return {
        ...this.$listeners,
        blur: (...args) => {
          this.$emit(`${this.field.inputType}-blur`, ...args)
          this.$nextTick(() => {
            this.$emit('field-blur')
          })
        },
      }
    },
    attrs() {
      return this.$attrs
    },
    containerListeners() {
      const f = {}
      if (this.mode === 'builder') {
        f['field-change'] = this.$listeners['field-change']
        f['field-remove'] = this.$listeners['field-remove']
        f['field-remove-trigger'] = this.$listeners['field-remove-trigger']
        f['field-edit'] = this.$listeners['field-edit']
        f['field-hover-clear'] = this.$listeners['field-hover-clear']
        f['duplicate'] = this.$listeners['duplicate']
      }
      return f
    },
    containerAttrs() {
      const a = {}
      a['current-hover-active-item-id'] =
        this.$attrs['current-hover-active-item-id']
      a['allow-split-field'] = this.$attrs['allow-split-field']
      a['is-dragging'] = this.$attrs['is-dragging']
      a['current-editing-item'] = this.$attrs['current-editing-item']
      a['mode'] = this.mode
      a['hide-action'] = this.hideAction
      return a
    },
    singleFieldRulesState() {
      const fieldKey = this.field.isSystemField
        ? this.field.paramName
        : this.field.id
      return this.fieldRulesState[fieldKey] || {}
    },
    // isRuleDirty() {
    //   if (this.mode === 'builder' || !this.applyFormRules) {
    //     return false
    //   }
    //   return this.singleFieldRulesState.isDirty || false
    // },
    // isHide() {
    //   if (this.mode === 'builder' || !this.applyFormRules) {
    //     return false
    //   }
    //   return this.singleFieldRulesState.hide
    // },
    isMandatory() {
      if (this.mode === 'builder' || !this.applyFormRules) {
        return false
      }
      return this.singleFieldRulesState.mandatory
    },
    isDisable() {
      if (this.mode === 'builder' || !this.applyFormRules) {
        return false
      }
      if (this.isPortalLogin && !this.singleFieldRulesState.disable) {
        if (
          !this.field.requesterCanEdit &&
          useOnPortalEnabledModules.indexOf(this.field.moduleName) >= 0
        ) {
          return true
        } else {
          return false
        }
      }
      if (this.field.required) {
        return false
      }
      return this.singleFieldRulesState.disable
    },
    hiddenOptionsKeys() {
      if (this.mode === 'builder' || !this.applyFormRules) {
        return []
      }
      return this.singleFieldRulesState.hiddenOptionsKeys
    },
    visibleOptionsKeys() {
      if (this.mode === 'builder' || !this.applyFormRules) {
        return []
      }
      return this.singleFieldRulesState.visibleOptionsKeys
    },
  },
  watch: {
    singleFieldRulesState: {
      immediate: true,
      handler(newValue) {
        if ('setValue' in newValue && newValue.setValue) {
          this.$emit('update-value', newValue.setValue)
        }
        if ('clearValue' in newValue) {
          this.$emit('update-value', newValue.clearValue)
        }
      },
    },
  },
}
