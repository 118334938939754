import { getModuleTranslator } from '@utils/get-module-translator'
import { generateId } from '@utils/id'
import moduleConfig from '../../config'

const __mtc = getModuleTranslator(moduleConfig.translationKey)

export const softwareProperyFieldMap = () => ({
  software_property_group: {
    title: __mtc('software_property_group'),
    fields: [
      {
        key: generateId(),
        paramName: 'softwareCost',
        name: `${__mtc('software')} ${__mtc('cost')}`,
        inputType: 'number',
      },
      {
        key: generateId(),
        paramName: 'licenseValidity',
        name: `${__mtc('license')} ${__mtc('expiry_date')}`,
        inputType: 'date',
      },
      {
        key: generateId(),
        paramName: 'licenceTypeId',
        name: `${__mtc('licence')} ${__mtc('type')}`,
        inputType: 'softwareLicenseType',
      },
      {
        key: generateId(),
        paramName: 'lastAuditDate',
        name: `${__mtc('last_audit')} ${__mtc('date')}`,
        inputType: 'date',
      },
      {
        key: generateId(),
        paramName: 'licenseKeys',
        name: `${__mtc('license_key')}`,
        inputType: 'tags',
      },
      {
        key: generateId(),
        paramName: 'executableFileNames',
        name: `${__mtc('executable_file_names')}`,
        inputType: 'tags',
      },
    ],
  },
})
