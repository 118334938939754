<template>
  <MCol
    v-if="!isHide"
    class="single-control"
    :class="{
      'rich-editor': field.isSystemField && field.inputType === 'description',
    }"
    :size="size"
  >
    <MRow :gutter="0">
      <MCol
        :id="
          field.isSystemField
            ? `system-field-${field.paramName}`
            : `custom-field-${field.id}`
        "
        class="mx-2"
      >
        <component
          :is="formField"
          :field="field"
          :apply-form-rules="applyFormRules"
          :field-rules-state="fieldRulesState"
          mode="consumer"
          v-bind="attrs"
          v-on="$listeners"
        >
          <slot />
        </component>
      </MCol>
    </MRow>
  </MCol>
</template>

<script>
import Section from './section'
import Text from './text'
import Dropdown from './dropdown'
import Dependent from './dependent'
import Radio from './radio'
import Checkbox from './checkbox'
import Attachment from './attachment'
import Label from './label'
import System from './system'
import Datetime from './datetime'
import Number from './number'
import Spacer from './spacer'
import Api from './api'
import Dynamic from './dynamic'
import Rating from './rating'
import Slider from './slider'
import Boolean from './boolean'
import FieldRule from '../../mixins/field-rule'

export default {
  name: 'Field',
  mixins: [FieldRule],
  inheritAttrs: false,
  props: {
    field: { type: Object, required: true },
    sectionId: { type: String, required: false, default: undefined },
  },
  computed: {
    classes() {
      return [(this.field.attributes || {}).widthClass]
    },
    size() {
      if ((this.field.attributes || {}).widthClass === 'w-1/2') {
        return 6
      }
      return 12
    },
    formField() {
      if (this.field.type === 'section') {
        return Section
      }
      if (this.field.type === 'spacer') {
        return Spacer
      }
      if (this.field.type === 'dropdown') {
        return Dropdown
      }
      if (this.field.type === 'dependent') {
        return Dependent
      }
      if (this.field.type === 'radio') {
        return Radio
      }
      if (this.field.type === 'checkbox') {
        return Checkbox
      }
      if (this.field.type === 'upload') {
        return Attachment
      }
      if (this.field.type === 'label') {
        return Label
      }
      if (this.field.type === 'datetime') {
        return Datetime
      }
      if (this.field.type === 'number') {
        return Number
      }
      if (this.field.type === 'api') {
        return Api
      }
      if (this.field.type === 'dynamic') {
        return Dynamic
      }
      if (this.field.type === 'rating') {
        return Rating
      }
      if (this.field.type === 'slider') {
        return Slider
      }
      if (this.field.type === 'boolean') {
        return Boolean
      }
      if (this.field.isSystemField) {
        return System
      }
      return Text
    },
    sectionFields() {
      if (this.field.fields) {
        return this.field.fields
      }
      return []
    },
    attrs() {
      const { placeholder, rows, multiple, disabled } = this.field.attributes
      const attrs = {
        ...this.$attrs,
        ...(disabled ? { disabled } : {}),
        multiple,
        placeholder:
          placeholder || (this.field.isSystemField ? this.field.title : ''),
        rows,
      }
      return attrs
    },
  },
}
</script>

<style lang="less" scoped>
.single-control {
  flex: unset;
  user-select: none;
  transition: width 0.4s ease;

  &.rich-editor {
    user-select: unset;
  }
}
</style>
