import api from '@api/'
import {
  getModuleTranslator,
  getRootTranslator,
} from '@utils/get-module-translator'
import { transformRegisterData } from './helpers'
import configs from './config'

const __t = getModuleTranslator(configs.translationKey)
const __rootT = getRootTranslator()

/**
 * Register user with api
 * @param {object} data
 * @return {Promise<object>}
 */
export function registerTenantApi(data) {
  return api.post('/public/register', transformRegisterData(data), {
    notify: false,
  })
}

export function registerApi(data) {
  return api.post('/public/requester', data, {
    notify: false,
  })
}

export function forgotPasswordApi(email, aditionalParams = {}) {
  return api.post(
    '/public/user/resetPassword',
    { email },
    {
      params: {
        ...aditionalParams,
      },
      notify: false,
    }
  )
}

export function changePasswordApi(data, token) {
  return api.post('/public/user/changePassword', data, {
    params: {
      uarToken: token,
    },
    notification: {
      message: __rootT('success'),
      description: __t('password_reset_success'),
    },
  })
}

export function validatePasswordResetTokenApi(token) {
  return api.get('/public/validateToken', {
    params: {
      uarToken: token,
    },
    notify: false,
  })
}

export function getEmailTwoFactorAuthApi(token) {
  return api.get(`/email/2fa?mid=${token}`, {
    notify: false,
  })
}

export function validateTwoFactorAuthApi(token, code) {
  return api.get(`/validate/2fa?validationcode=${code}&mid=${token}`)
}

export function changePasswordForRequesteApi(data, token) {
  return api.post(`user/requester/changeonetimepassword`, data, {
    params: {
      mid: token,
    },
    notify: false,
  })
}

export function googleSSOLoginApi(token, provider = 'google') {
  return api.post(
    `public/sso/${provider}/login`,
    {
      token,
    },
    {
      notify: true,
    }
  )
}
