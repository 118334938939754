import api from '@api'

import {
  getRootTranslator,
  getRootPluaralTranslator,
} from '@utils/get-module-translator'

import {
  transformSupportData,
  transformWorkflowForList,
  // transformModuleNameForServer,
} from '@data/workflow'
import {
  transformApprovalWorkflowForServer,
  transformApprovalWorkflow,
  transformApprovalSettingsForServer,
  transformApprovalSettings,
} from '@data/approval'
import {
  buildFlatQualificationStructure,
  buildNameFilterQuery,
} from '@data/qualification'
import { isPortalLogin } from '@utils/auth'

const __rootT = getRootTranslator()
const __rootTc = getRootPluaralTranslator()

export function getSupportDataApi(model, id) {
  return api
    .get(`${model}${id ? `/${id}` : ''}/approval/supportedData`)
    .then(transformSupportData)
}

export function createApprovalWorkflowApi(workflow) {
  return api.post(
    `${workflow.moduleName}${
      workflow.parentId ? `/${workflow.parentId}` : ''
    }/approvalworkflow`,
    transformApprovalWorkflowForServer(workflow),
    {
      message: __rootT('created_successfully', {
        resource: `${__rootTc('approval')} ${__rootT('workflow')}`,
      }),
    }
  )
}

export function getApprovalWorkflowApi(moduleName, id) {
  return api
    .get(`${moduleName}/approvalworkflow/${id}`)
    .then((data) => transformApprovalWorkflow(data))
}

export function getApprovalWorkflowsApi(
  { moduleName, filter, parentId },
  limit,
  offset
) {
  return api
    .post(
      `${moduleName}${
        parentId ? `/${parentId}` : ''
      }/approvalworkflow/search/byqual`,
      {
        qualDetails: filter
          ? buildFlatQualificationStructure([buildNameFilterQuery(filter)])
          : undefined,
      },
      {
        params: {
          offset: offset || 0,
          size: limit === undefined ? 1000 : limit,
        },
        notify: false,
      }
    )
    .then((data) => {
      return {
        items: (data.objectList || []).map(transformWorkflowForList),
        total: data.totalCount,
      }
    })
}

export function updateApprovalWorkflowApi(moduleName, data) {
  return api.patch(
    `${moduleName}/approvalworkflow/${data.id}`,
    transformApprovalWorkflowForServer(data),
    {
      message: __rootT('saved_successfully', {
        resource: `${__rootTc('approval')} ${__rootT('workflow')}`,
      }),
    }
  )
}

export function bulkUpdateApprovalApi(moduleName, items) {
  const payload = {}
  items.forEach((i) => {
    payload[i.id] = { objOrder: i.order }
  })
  return api.patch(`${moduleName}/approvalworkflow/bulk/update`, payload, {
    message: __rootT('saved_successfully', {
      resource: `${__rootTc('approval')} ${__rootT('workflow')}`,
    }),
  })
}

export function toggleEnableApi(moduleName, id, data) {
  return api
    .patch(`${moduleName}/approvalworkflow/${id}`, data, {
      message: __rootT('updated_successfully', {
        resource: `${__rootTc('approval')} ${__rootT('workflow')}`,
      }),
    })
    .then((data) => transformWorkflowForList(data))
}

export function deleteApprovalWorkflowApi(moduleName, id) {
  return api.delete(`${moduleName}/approvalworkflow/${id}`, {
    message: __rootT('deleted_successfully', {
      resource: `${__rootTc('approval')} ${__rootT('workflow')}`,
    }),
  })
}

export function getApprovalSettingApi() {
  return api
    .get(`${isPortalLogin() ? '/cportal' : ''}/approvalsetting`)
    .then(transformApprovalSettings)
}

export function updateApprovalSettingApi(data) {
  return api
    .patch('/approvalsetting', transformApprovalSettingsForServer(data), {
      message: __rootT('updated_successfully', {
        resource: `${__rootTc('approval')} ${__rootTc('setting')}`,
      }),
    })
    .then(transformApprovalSettings)
}
