<template>
  <FieldContainer
    :field="field"
    :section-id="sectionId"
    v-bind="containerAttrs"
    v-on="containerListeners"
  >
    <FlotoFormItem v-if="disabled" :label="field.title">
      {{ currentValue || '---' }}
    </FlotoFormItem>
    <FlotoFormItem
      v-else
      v-model="currentValue"
      :class="{
        'w-full': field.attributes.widthClass !== 'w-full',
        'w-1/4': field.attributes.widthClass === 'w-full',
      }"
      type="number"
      :read-only="true"
      :rules="validationsRules"
      :precision="field.attributes.allowDecimal ? 2 : 0"
      :label="field.title"
      :placeholder="field.attributes.placeholder"
      :disabled="
        disabled ||
        mode === 'builder' ||
        field.attributes.systemManagedField ||
        isDisable
      "
      v-bind="attrs"
      v-on="listeners"
    />
  </FieldContainer>
</template>

<script>
import FormField from '../../mixins/form-field'

export default {
  name: 'Datetime',
  mixins: [FormField],
  computed: {
    isRequired() {
      return (
        (!this.isPortalLogin && this.field.required) ||
        (this.isPortalLogin && this.field.requesterRequired) ||
        this.isMandatory
      )
    },
    validationsRules() {
      const rules = {
        required: this.isRequired || false,
        nonzero: this.isRequired || false,
        // eslint-disable-next-line
        max_value: 999999999999999,
        // eslint-disable-next-line
        min_value: -999999999999999,
      }
      // apply form rule action for mandatory non-mandatory
      // if (this.isRuleDirty) {
      //   rules.required = this.isMandatory || false
      //   rules.nonzero = this.isMandatory || false
      // }
      // if field rule is disable then remove required validation
      if (this.isDisable) {
        rules.required = false
        rules.nonzero = false
      }
      return rules
    },
  },
}
</script>
