import {
  getRootTranslator,
  getRootPluaralTranslator,
} from '@utils/get-module-translator'
import Constants from '@constants'
import moduleManager from '@modules'

import IntersectionWith from 'lodash/intersectionWith'
import IsEqual from 'lodash/isEqual'
const __rootTc = getRootPluaralTranslator()
const __rootT = getRootTranslator()

export const moduleList = () => [
  {
    key: 'automation',
    heading: __rootT('automation'),
    iconProps: {
      name: 'tire-rugged',
    },
    isModuleEnabledForUser(userAllowedModules) {
      return userAllowedModules.indexOf('admin.automation') >= 0
    },
    items: [
      {
        key: 'workflow',
        title: __rootT('workflow'),
        action: moduleManager.getModuleRoute('workflow'),
        disable: false,
        isModuleAvailable: (allAvailableModule) =>
          allAvailableModule.indexOf(Constants.REQUEST) >= 0 ||
          allAvailableModule.indexOf(Constants.RELEASE) >= 0 ||
          allAvailableModule.indexOf(Constants.ASSET) >= 0,
      },
      {
        key: 'sla',
        title: __rootT('sla'),
        disable: false,
        action: moduleManager.getModuleRoute('sla'),
        isModuleAvailable: (allAvailableModule) =>
          allAvailableModule.indexOf(Constants.REQUEST) >= 0,
      },
      {
        key: 'approval-workflow',
        title: `${__rootTc('approval')} ${__rootT('workflow')}`,
        action: moduleManager.getModuleRoute('approval'),
        disable: false,
        isModuleAvailable: (allAvailableModule) =>
          allAvailableModule.indexOf(Constants.REQUEST) >= 0 ||
          allAvailableModule.indexOf(Constants.ASSET) >= 0,
      },
      {
        key: 'event-notification',
        title: __rootTc('event_notifications'),
        disable: false,
        action: moduleManager.getModuleRoute('event-notifications'),
        keywords: [
          __rootTc('email_notification'),
          __rootTc('sms_notification'),
          __rootTc('sms_gateway_configuration'),
        ],
      },
      {
        key: 'auto-assignment',
        title: __rootT('auto_assignment'),
        disable: false,
        action: moduleManager.getModuleRoute('auto-assignment'),
        isModuleAvailable: (allAvailableModule) =>
          allAvailableModule.indexOf(Constants.REQUEST) >= 0,
      },
      {
        key: 'scenario',
        title: __rootT('scenario'),
        disable: false,
        action: moduleManager.getModuleRoute('scenario'),
        isModuleAvailable: (allAvailableModule) =>
          allAvailableModule.indexOf(Constants.REQUEST) >= 0,
      },
      {
        key: 'incident-scheduler',
        title: __rootTc('incident_scheduler', 2),
        disable: false,
        action: moduleManager.getModuleRoute('auto-ticket-create'),
        isModuleAvailable: (allAvailableModule) =>
          allAvailableModule.indexOf(Constants.REQUEST) >= 0,
      },
      {
        key: 'task-scheduler',
        title: __rootTc('task_schedule', 2),
        disable: false,
        action: moduleManager.getModuleRoute('auto-task-create'),
        isModuleAvailable: (allAvailableModule) =>
          allAvailableModule.indexOf(Constants.TASK) >= 0,
      },
      {
        key: 'integration',
        title: __rootTc('integration', 2),
        action: moduleManager.getModuleRoute('integration'),
        keywords: [
          __rootTc('rest_integration'),
          __rootTc('app_integration'),
          __rootTc('plugin'),
          `${__rootTc('report')} ${__rootTc('plugin')}`,
          `${__rootTc('chat')} ${__rootTc('plugin')}`,
        ],
      },
      {
        key: 'smart-suggestions',
        title: `${__rootT('smart_suggestions')}`,
        disable: false,
        action: moduleManager.getModuleRoute('smart-suggestions', undefined, {
          params: { moduleName: Constants.REQUEST },
          query: { type: Constants.REQUEST },
        }),
      },
    ],
  },
  {
    key: 'users',
    heading: __rootTc('user', 2),
    iconProps: {
      name: 'user-cog',
    },
    isModuleEnabledForUser(userAllowedModules) {
      return userAllowedModules.indexOf('admin.users') >= 0
    },
    items: [
      {
        key: 'technicians',
        title: __rootTc('technician', 2),
        disable: false,
        action: moduleManager.getModuleRoute('users', undefined, {
          params: { userType: 'technician' },
        }),
      },
      {
        key: 'requesters',
        title: __rootTc('requester', 2),
        disable: false,
        action: moduleManager.getModuleRoute('users', undefined, {
          params: { userType: 'requester' },
        }),
      },
      {
        key: 'user-custom-fields',
        title: `${__rootTc('user')} ${__rootT('custom_fields')}`,
        disable: false,
        action: moduleManager.getModuleRoute('form', undefined, {
          params: { moduleName: 'user' },
        }),
      },
      {
        key: 'technician-group',
        title: `${__rootTc('technician')} ${__rootTc('group', 2)}`,
        disable: false,
        action: moduleManager.getModuleRoute('user-groups', undefined, {
          params: { groupType: 'technician' },
        }),
      },
      {
        key: 'requester-group',
        title: `${__rootTc('requester')} ${__rootTc('group', 2)}`,
        disable: false,
        action: moduleManager.getModuleRoute('user-groups', undefined, {
          params: { groupType: 'requester' },
        }),
      },
      {
        key: 'roles',
        title: __rootTc('role', 2),
        disable: false,
        action: moduleManager.getModuleRoute('roles'),
      },
      {
        key: 'ldap-configurations',
        title: `${__rootT('ldap_configurations')}`,
        disable: false,
        action: moduleManager.getModuleRoute(
          'it-infrastructure',
          'ldap-configurations'
        ),
      },
      {
        key: 'sso-configuration',
        title: `${__rootT('sso_configuration')}`,
        disable: false,
        action: moduleManager.getModuleRoute(
          'it-infrastructure',
          'sso-configuration'
        ),
      },
    ],
  },
  {
    key: 'organization',
    heading: __rootT('organization'),
    iconProps: {
      name: 'building',
    },
    colSize: 6,
    splitItems: 2,
    isModuleEnabledForUser(userAllowedModules) {
      return userAllowedModules.indexOf('admin.organization') >= 0
    },
    items: [
      {
        key: 'account',
        title: __rootTc('account'),
        action: moduleManager.getModuleRoute('organization', 'account'),
        disable: false,
      },
      {
        key: 'branding',
        title: __rootTc('branding'),
        action: moduleManager.getModuleRoute('organization', 'branding'),
        disable: false,
        keywords: [
          __rootTc('theme'),
          __rootTc('support_portal'),
          __rootTc('technician_portal'),
        ],
      },
      {
        key: 'departments',
        title: __rootTc('department', 2),
        disable: false,
        action: moduleManager.getModuleRoute('organization', 'department'),
      },
      {
        key: 'locations',
        title: __rootTc('location', 2),
        disable: false,
        action: moduleManager.getModuleRoute('organization', 'location'),
      },
      {
        key: 'business-hours',
        title: `${__rootT('business')} ${__rootTc('hours', 2)}`,
        disable: false,
        action: moduleManager.getModuleRoute('business-hours'),
      },
      {
        key: 'announcements',
        title: __rootTc('announcement', 2),
        action: moduleManager.getModuleRoute('organization', 'announcement'),
        disable: false,
      },
      {
        key: 'business-service',
        title: `${__rootTc('business')} ${__rootTc('service')}`,
        action: moduleManager.getModuleRoute('business-service'),
        disable: false,
        isModuleAvailable: (allAvailableModule) => {
          const isAvailable = IntersectionWith(
            [
              Constants.PROBLEM,
              Constants.CHANGE,
              Constants.RELEASE,
              Constants.ASSET,
            ],
            allAvailableModule,
            IsEqual
          )
          return !!isAvailable.length
        },
      },
      {
        key: 'system-preference',
        title: __rootTc('system_preference'),
        action: moduleManager.getModuleRoute(
          'organization',
          'system-preference'
        ),
        disable: false,
        keywords: [
          __rootTc('request_preference'),
          __rootTc('request_preference'),
          __rootTc('asset_preference'),
          `${__rootTc('agent')} ${__rootTc('preference')}`,
          __rootTc('survey_preference'),
          __rootTc('project_preference'),
          __rootTc('application_settings'),
        ],
      },
      {
        key: 'security',
        title: __rootTc('security'),
        action: moduleManager.getModuleRoute('organization', 'security'),
        disable: false,
        keywords: [
          __rootTc('configuration_activity'),
          __rootTc('operation_activity'),
          __rootTc('password_policy'),
          __rootTc('user_security'),
          __rootTc('user_sessions'),
          __rootTc('ip_address_restriction'),
        ],
      },
      {
        key: 'priority',
        title: __rootTc('priority'),
        action: moduleManager.getModuleRoute('organization', 'priority'),
        disable: false,
        isModuleAvailable: (allAvailableModule) =>
          allAvailableModule.indexOf(Constants.REQUEST) >= 0,
      },
      {
        key: 'impact',
        title: __rootTc('impact'),
        action: moduleManager.getModuleRoute('organization', 'impact'),
        disable: false,
        isModuleAvailable: (allAvailableModule) =>
          allAvailableModule.indexOf(Constants.REQUEST) >= 0,
      },
      {
        key: 'urgency',
        title: __rootTc('urgency'),
        action: moduleManager.getModuleRoute('organization', 'urgency'),
        disable: false,
        isModuleAvailable: (allAvailableModule) =>
          allAvailableModule.indexOf(Constants.REQUEST) >= 0,
      },
      {
        key: 'application-maintenance',
        title: `${__rootTc('application_maintenance')}`,
        disable: false,
        action: moduleManager.getModuleRoute('support-console'),
        keywords: [
          __rootTc('data_indexing'),
          __rootTc('language_preference'),
          __rootTc('database_backup'),
          __rootTc('data_archiving'),
          __rootTc('product_license'),
          __rootTc('log_download'),
        ],
      },
      {
        key: 'task-type',
        title: `${__rootTc('task_type', 2)}`,
        action: moduleManager.getModuleRoute('organization', 'task-type'),
        disable: false,
        isModuleAvailable: (allAvailableModule) =>
          allAvailableModule.indexOf(Constants.REQUEST) >= 0 ||
          allAvailableModule.indexOf(Constants.PROJECT) >= 0,
      },
      {
        key: 'msp',
        title: __rootTc('msp'),
        action: moduleManager.getModuleRoute('organization', 'msp'),
        disable: false,
        mspEnabled: (mspEnabled) => mspEnabled,
      },
      {
        key: 'tenant-management',
        title: `${__rootTc('tenant_management')}`,
        action: moduleManager.getModuleRoute(
          'organization',
          'tenant-management'
        ),
        disable: false,
        multiTenantEnabled: (multiTenantEnabled) => multiTenantEnabled,
      },
    ],
  },
  {
    key: 'it_infrastructure',
    heading: __rootTc('support_channel', 2),
    iconProps: {
      name: 'laptop-code',
    },
    isModuleEnabledForUser(userAllowedModules) {
      return userAllowedModules.indexOf('admin.support_channels') >= 0
    },
    items: [
      {
        key: 'emails',
        title: `${__rootTc('email', 2)}`,
        disable: false,
        keywords: [
          __rootTc('outgoing_email_server'),
          __rootTc('incoming_email_server'),
          __rootTc('email_preference'),
        ],
        action: moduleManager.getModuleRoute(
          'it-infrastructure',
          'email-server-config'
        ),
        isModuleAvailable: (allAvailableModule) =>
          allAvailableModule.indexOf(Constants.REQUEST) >= 0 ||
          allAvailableModule.indexOf(Constants.ASSET) >= 0 ||
          allAvailableModule.indexOf(Constants.PROJECT) >= 0 ||
          allAvailableModule.indexOf(Constants.PATCH) >= 0,
      },
      {
        key: 'support-portal',
        title: `${__rootT('support_portal')}`,
        disable: false,
        action: moduleManager.getModuleRoute(
          'it-infrastructure',
          'support-portal'
        ),
      },
      {
        key: 'chat',
        title: `${__rootT('chat')}`,
        disable: false,
        action: moduleManager.getModuleRoute('it-infrastructure', 'chat'),
        isModuleAvailable: (allAvailableModule) =>
          allAvailableModule.indexOf(Constants.REQUEST) >= 0,
      },
      {
        key: 'ai-bot',
        title: `${__rootTc('ai_bot')}`,
        disable: false,
        keywords: [
          __rootTc('chat_flow'),
          __rootTc('slack_app_config'),
          __rootTc('teams_app_config'),
          __rootTc('telegram_app_config'),
        ],
        action: moduleManager.getModuleRoute('it-infrastructure', 'ai-bot'),
        isModuleAvailable: (allAvailableModule) =>
          allAvailableModule.indexOf(Constants.REQUEST) >= 0,
      },
      {
        key: 'messaging-app',
        title: `${__rootTc('messaging_app')}`,
        disable: false,
        keywords: [__rootTc('telegram'), __rootTc('whatsapp')],
        action: moduleManager.getModuleRoute(
          'it-infrastructure',
          'messaging-app'
        ),
        isModuleAvailable: (allAvailableModule) =>
          allAvailableModule.indexOf(Constants.REQUEST) >= 0,
      },
      // @TODO enable in next release
      // {
      //   title: `${__rootT('telephony')}`,
      //   disable: false,
      //   action: moduleManager.getModuleRoute('contactops'),
      //   isModuleAvailable: (allAvailableModule) =>
      //     allAvailableModule.indexOf(Constants.REQUEST) >= 0,
      // },
    ],
  },
  {
    key: 'request_management',
    heading: `${__rootTc('request')} ${__rootT('management')}`,
    iconProps: {
      name: 'ticket-alt',
    },
    colSize: 6,
    splitItems: 2,
    isModuleEnabledForUser(userAllowedModules) {
      return userAllowedModules.indexOf('admin.request_management') >= 0
    },
    isModuleAvailable: (allAvailableModule) =>
      allAvailableModule.indexOf(Constants.REQUEST) >= 0,
    items: [
      {
        key: 'request-form',
        title: `${__rootTc('request')} ${__rootT('form')}`,
        action: moduleManager.getModuleRoute('form', undefined, {
          params: { moduleName: Constants.REQUEST },
        }),
        disable: false,
      },
      {
        key: 'request-status',
        title: `${__rootTc('request')} ${__rootTc('status')}`,
        action: moduleManager.getModuleRoute('status', undefined, {
          params: { moduleName: Constants.REQUEST },
          query: { type: Constants.REQUEST },
        }),
      },
      {
        key: 'priority-matrix',
        title: __rootT('priority_matrix'),
        action: moduleManager.getModuleRoute('request-management', 'matrix'),
        disable: false,
      },
      {
        key: 'request-feedback',
        title: `${__rootTc('request')} ${__rootTc('feedback')} ${__rootTc(
          'setting'
        )}`,
        action: moduleManager.getModuleRoute(
          'request-management',
          'feedback_setting'
        ),
        disable: false,
      },
      {
        key: 'response-template',
        title: __rootTc('response_template', 2),
        action: moduleManager.getModuleRoute('response-template'),
        disable: false,
      },
      {
        key: 'email-command-settings',
        title: __rootTc('email_command_settings'),
        action: moduleManager.getModuleRoute(
          'request-management',
          'email_command_settings'
        ),
        disable: false,
      },
      {
        key: 'request-category',
        title: `${__rootTc('request')} ${__rootTc('category', 2)}`,
        action: moduleManager.getModuleRoute('category', undefined, {
          params: { moduleName: Constants.REQUEST },
          query: { type: Constants.REQUEST },
        }),
        disable: false,
      },
      {
        key: 'request-template',
        title: `${__rootTc('request')} ${__rootTc('template', 2)}`,
        action: moduleManager.getModuleRoute('templates', undefined, {
          params: { moduleName: Constants.REQUEST },
          query: { type: Constants.REQUEST },
        }),
        disable: false,
      },
      {
        key: 'feedback-form',
        title: `${__rootTc('feedback')} ${__rootTc('form')}`,
        action: moduleManager.getModuleRoute('form', undefined, {
          params: { moduleName: 'feedback' },
        }),
        disable: false,
      },
      {
        key: 'request-custom-rules',
        title: `${__rootTc('request')} ${__rootTc('custom_rules')}`,
        action: moduleManager.getModuleRoute('custom-rules', undefined, {
          params: { moduleName: Constants.REQUEST },
          query: { type: Constants.REQUEST },
        }),
        disable: false,
      },
      {
        title: `${__rootTc('request')} ${__rootTc('form_rule')}`,
        action: moduleManager.getModuleRoute('form-rules', undefined, {
          params: { moduleName: Constants.REQUEST },
        }),
        disable: false,
      },
      {
        key: 'source',
        title: __rootTc('source'),
        action: moduleManager.getModuleRoute('request-management', 'source'),
        disable: false,
      },
    ],
  },
  {
    key: 'service_catalog',
    heading: __rootT('service_catalog'),
    iconProps: {
      name: 'map',
    },
    isModuleEnabledForUser(userAllowedModules) {
      return userAllowedModules.indexOf('admin.service_catalog') >= 0
    },
    isModuleAvailable: (allAvailableModule) =>
      allAvailableModule.indexOf(Constants.SERVICE_CATALOG) >= 0,
    items: [
      {
        key: 'service-catalog',
        title: __rootT('service_catalog'),
        action: moduleManager.getModuleRoute('service-catalog'),
        disable: false,
      },
    ],
  },
  {
    key: 'problem_management',
    heading: `${__rootTc('problem')} ${__rootT('management')}`,
    iconProps: {
      name: 'problem',
    },
    isModuleEnabledForUser(userAllowedModules) {
      return userAllowedModules.indexOf('admin.problem_management') >= 0
    },
    isModuleAvailable: (allAvailableModule) =>
      allAvailableModule.indexOf(Constants.PROBLEM) >= 0,
    items: [
      {
        key: 'problem-form',
        title: `${__rootTc('problem')} ${__rootT('form')}`,
        action: moduleManager.getModuleRoute('form', undefined, {
          params: { moduleName: 'problem' },
        }),
        disable: false,
      },
      {
        key: 'problem-category',
        title: `${__rootTc('problem')} ${__rootTc('category', 2)}`,
        action: moduleManager.getModuleRoute('category', undefined, {
          params: { moduleName: 'problem' },
          query: { type: 'problem' },
        }),
        disable: false,
      },
      {
        key: 'problem-status',
        title: `${__rootTc('problem')} ${__rootTc('status')}`,
        action: moduleManager.getModuleRoute('status', undefined, {
          params: { moduleName: 'problem' },
          query: { type: 'problem' },
        }),
      },
      {
        key: 'problem-template',
        title: `${__rootTc('problem')} ${__rootTc('template', 2)}`,
        action: moduleManager.getModuleRoute('templates', undefined, {
          params: { moduleName: 'problem' },
          query: { type: 'problem' },
        }),
        disable: false,
      },
      {
        key: 'problem-custom-rules',
        title: `${__rootTc('problem')} ${__rootTc('custom_rules')}`,
        action: moduleManager.getModuleRoute('custom-rules', undefined, {
          params: { moduleName: 'problem' },
          query: { type: 'problem' },
        }),
        disable: false,
      },
    ],
  },
  {
    key: 'change_management',
    heading: `${__rootTc('change')} ${__rootT('management')}`,
    colSize: 6,
    splitItems: 2,
    iconProps: {
      name: 'change',
    },
    isModuleEnabledForUser(userAllowedModules) {
      return userAllowedModules.indexOf('admin.change_management') >= 0
    },
    isModuleAvailable: (allAvailableModule) =>
      allAvailableModule.indexOf(Constants.CHANGE) >= 0,
    items: [
      {
        key: 'change-form',
        title: `${__rootTc('change')} ${__rootT('form')}`,
        action: moduleManager.getModuleRoute('form', undefined, {
          params: { moduleName: 'change' },
        }),
        disable: false,
      },
      {
        key: 'change-status',
        title: `${__rootTc('change')} ${__rootTc('status')}`,
        action: moduleManager.getModuleRoute('status', undefined, {
          params: { moduleName: 'change' },
          query: { type: 'change' },
        }),
      },
      {
        key: 'change-custom-rules',
        title: `${__rootTc('change')} ${__rootTc('custom_rules')}`,
        action: moduleManager.getModuleRoute('custom-rules', undefined, {
          params: { moduleName: 'change' },
          query: { type: 'change' },
        }),
        disable: false,
      },
      {
        key: 'change-type',
        title: `${__rootTc('change_type', 2)}`,
        disable: false,
        action: moduleManager.getModuleRoute(
          'change-management',
          'change-type'
        ),
      },
      {
        key: 'target-environment',
        title: `${__rootTc('target_environment')}`,
        disable: false,
        action: moduleManager.getModuleRoute(
          'change-management',
          'target-environment'
        ),
      },
      {
        key: 'change-category',
        title: `${__rootTc('change')} ${__rootTc('category', 2)}`,
        action: moduleManager.getModuleRoute('category', undefined, {
          params: { moduleName: 'change' },
          query: { type: 'change' },
        }),
        disable: false,
      },
      {
        key: 'change-template',
        title: `${__rootTc('change')} ${__rootTc('template', 2)}`,
        action: moduleManager.getModuleRoute('templates', undefined, {
          params: { moduleName: 'change' },
          query: { type: 'change' },
        }),
        disable: false,
      },
      {
        key: 'change-risk',
        title: `${__rootTc('change_risk')}`,
        disable: false,
        action: moduleManager.getModuleRoute('change-management', 'risk-type'),
      },
      {
        key: 'change-reason',
        title: `${__rootTc('change_reason')}`,
        disable: false,
        action: moduleManager.getModuleRoute(
          'change-management',
          'reason-type'
        ),
      },
    ],
  },
  {
    key: 'release_management',
    heading: `${__rootTc('release')} ${__rootT('management')}`,
    // colSize: 6,
    // splitItems: 2,
    iconProps: {
      name: 'box-full',
    },
    isModuleEnabledForUser(userAllowedModules) {
      return userAllowedModules.indexOf('admin.release_management') >= 0
    },
    isModuleAvailable: (allAvailableModule) =>
      allAvailableModule.indexOf(Constants.RELEASE) >= 0,
    items: [
      {
        key: 'release-form',
        title: `${__rootTc('release')} ${__rootT('form')}`,
        action: moduleManager.getModuleRoute('form', undefined, {
          params: { moduleName: Constants.RELEASE },
        }),
      },
      {
        key: 'release-status',
        title: `${__rootTc('release')} ${__rootTc('status')}`,
        action: moduleManager.getModuleRoute('status', undefined, {
          params: { moduleName: Constants.RELEASE },
          query: { type: Constants.RELEASE },
        }),
      },
      {
        key: 'release-category',
        title: `${__rootTc('release')} ${__rootTc('category', 2)}`,
        action: moduleManager.getModuleRoute('category', undefined, {
          params: { moduleName: 'release' },
          query: { type: 'release' },
        }),
        disable: false,
      },
      {
        key: 'release-type',
        title: `${__rootTc('release_type', 2)}`,
        disable: false,
        action: moduleManager.getModuleRoute(
          'release-management',
          'release-type'
        ),
      },
      {
        key: 'release-risk',
        title: `${__rootTc('release_risk')}`,
        disable: false,
        action: moduleManager.getModuleRoute('release-management', 'risk-type'),
      },
      {
        key: 'release-reason',
        title: `${__rootTc('release_reason')}`,
        disable: false,
        action: moduleManager.getModuleRoute(
          'release-management',
          'reason-type'
        ),
      },
      {
        key: 'release-template',
        title: `${__rootTc('release')} ${__rootTc('template', 2)}`,
        action: moduleManager.getModuleRoute('templates', undefined, {
          params: { moduleName: 'release' },
          query: { type: 'release' },
        }),
        disable: false,
      },
      {
        key: 'release-custom-rules',
        title: `${__rootTc('release')} ${__rootTc('custom_rules')}`,
        action: moduleManager.getModuleRoute('custom-rules', undefined, {
          params: { moduleName: 'release' },
          query: { type: 'release' },
        }),
        disable: false,
      },
    ],
  },
  {
    key: 'asset_management',
    heading: `${__rootTc('asset')} ${__rootT('management')}`,
    colSize: 6,
    splitItems: 2,
    iconProps: {
      name: 'laptop-code',
    },
    isModuleEnabledForUser(userAllowedModules) {
      return (
        userAllowedModules.indexOf('admin.asset_management') >= 0 ||
        userAllowedModules.indexOf('admin.asset_discovery') >= 0 ||
        userAllowedModules.indexOf('admin.asset_catalogs') >= 0 ||
        userAllowedModules.indexOf('admin.patch_management') >= 0
      )
    },
    isModuleAvailable: (allAvailableModule) => {
      return (
        allAvailableModule.indexOf(Constants.ASSET) >= 0 ||
        allAvailableModule.indexOf(Constants.PATCH) >= 0
      )
    },
    items: [
      {
        key: 'asset-types',
        title: `${__rootTc('asset_type', 2)}`,
        disable: false,
        action: moduleManager.getModuleRoute('asset-management', 'assetType'),
        isModuleEnabledForUser: (userAllowedModules) =>
          userAllowedModules.indexOf('admin.asset_management') >= 0,
        isModuleAvailable: (allAvailableModule) =>
          allAvailableModule.indexOf(Constants.ASSET) >= 0,
      },
      {
        key: 'asset-custom-fields',
        title: `${__rootTc('asset')} ${__rootT('custom_fields')}`,
        disable: false,
        action: moduleManager.getModuleRoute('form', undefined, {
          params: { moduleName: 'asset' },
        }),
        isModuleEnabledForUser: (userAllowedModules) =>
          userAllowedModules.indexOf('admin.asset_management') >= 0,
        isModuleAvailable: (allAvailableModule) =>
          allAvailableModule.indexOf(Constants.ASSET) >= 0,
      },
      {
        key: 'asset-status',
        title: `${__rootTc('asset')} ${__rootTc('status')}`,
        action: moduleManager.getModuleRoute('status', undefined, {
          params: { moduleName: 'asset' },
          query: { type: 'asset' },
        }),
        disable: false,
        isModuleEnabledForUser: (userAllowedModules) =>
          userAllowedModules.indexOf('admin.asset_management') >= 0,
        isModuleAvailable: (allAvailableModule) =>
          allAvailableModule.indexOf(Constants.ASSET) >= 0,
      },
      {
        key: 'asset-custom-rules',
        title: `${__rootTc('asset')} ${__rootTc('custom_rules')}`,
        action: moduleManager.getModuleRoute('custom-rules', undefined, {
          params: { moduleName: 'asset' },
          query: { type: 'asset' },
        }),
        disable: false,
        isModuleEnabledForUser: (userAllowedModules) =>
          userAllowedModules.indexOf('admin.asset_management') >= 0,
        isModuleAvailable: (allAvailableModule) =>
          allAvailableModule.indexOf(Constants.ASSET) >= 0,
      },
      {
        key: 'asset-group',
        title: `${__rootTc('asset')} ${__rootTc('group', 2)}`,
        disable: false,
        action: moduleManager.getModuleRoute('asset-management', 'asset-group'),
        isModuleEnabledForUser: (userAllowedModules) =>
          userAllowedModules.indexOf('admin.asset_management') >= 0,
        isModuleAvailable: (allAvailableModule) =>
          allAvailableModule.indexOf(Constants.ASSET) >= 0,
      },
      {
        key: 'snmp-custom-properties',
        title: `${__rootTc('snmp_custom_properties')}`,
        disable: false,
        action: moduleManager.getModuleRoute(
          'asset-management',
          'snmp-custom-properties'
        ),
        isModuleEnabledForUser: (userAllowedModules) =>
          userAllowedModules.indexOf('admin.asset_management') >= 0,
        isModuleAvailable: (allAvailableModule) =>
          allAvailableModule.indexOf(Constants.ASSET) >= 0,
      },
      {
        key: 'software-category',
        title: `${__rootTc('software')} ${__rootTc('category', 2)}`,
        disable: false,
        action: moduleManager.getModuleRoute(
          'asset-management',
          'software-category'
        ),
        isModuleEnabledForUser: (userAllowedModules) =>
          userAllowedModules.indexOf('admin.asset_management') >= 0,
        isModuleAvailable: (allAvailableModule) =>
          allAvailableModule.indexOf(Constants.ASSET) >= 0,
      },
      {
        key: 'software-type',
        title: `${__rootTc('software_type', 2)}`,
        disable: false,
        action: moduleManager.getModuleRoute(
          'asset-management',
          'software-type'
        ),
        isModuleEnabledForUser: (userAllowedModules) =>
          userAllowedModules.indexOf('admin.asset_management') >= 0,
        isModuleAvailable: (allAvailableModule) =>
          allAvailableModule.indexOf(Constants.ASSET) >= 0,
      },
      {
        key: 'relationship-type',
        title: __rootTc('relationship_type', 2),
        disable: false,
        action: moduleManager.getModuleRoute(
          'asset-management',
          'asset-relationship-types'
        ),
        isModuleEnabledForUser: (userAllowedModules) =>
          userAllowedModules.indexOf('admin.asset_management') >= 0,
        isModuleAvailable: (allAvailableModule) =>
          allAvailableModule.indexOf(Constants.ASSET) >= 0,
      },
      {
        key: 'asset-discovery',
        title: `${__rootTc('asset')} ${__rootTc('discovery')}`,
        disable: false,
        keywords: [
          __rootTc('discovery_service'),
          __rootTc('credentials'),
          __rootTc('network_scan'),
          __rootTc('domain_scan'),
          __rootTc('sccm_scan'),
          __rootTc('dns_configuration'),
          __rootTc('agent_installation'),
          __rootTc('agent_build'),
          __rootTc('agent_installation_history'),
          __rootTc('discovery_agent'),
          __rootTc('mobile_agent'),
        ],
        action: moduleManager.getModuleRoute(
          'asset-management',
          'assetDiscovery'
        ),
        isModuleEnabledForUser: (userAllowedModules) => {
          return (
            userAllowedModules.indexOf('admin.asset_discovery') >= 0 ||
            userAllowedModules.indexOf('admin.patch_management') >= 0
          )
        },
        isModuleAvailable: (allAvailableModule) => {
          return (
            allAvailableModule.indexOf(Constants.ASSET) >= 0 ||
            allAvailableModule.indexOf(Constants.PATCH) >= 0
          )
        },
      },
      {
        key: 'software-rules',
        title: `${__rootTc('software_rule', 2)}`,
        disable: false,
        keywords: [
          __rootTc('software_type_settings'),
          __rootTc('prohibited_software_rule'),
          __rootTc('mandatory_software'),
          __rootTc('software_normalization_rule'),
          __rootTc('auto_uninstallation_policy'),
        ],
        action: moduleManager.getModuleRoute(
          'asset-management',
          'software-rules'
        ),
        isModuleEnabledForUser: (userAllowedModules) =>
          userAllowedModules.indexOf('admin.asset_management') >= 0,
        isModuleAvailable: (allAvailableModule) =>
          allAvailableModule.indexOf(Constants.ASSET) >= 0,
      },
      {
        key: 'rdp-configurations',
        title: __rootTc('rdp_configuration', 2),
        disable: false,
        action: moduleManager.getModuleRoute(
          'asset-management',
          'rdp-configuration'
        ),
        isModuleEnabledForUser: (userAllowedModules) =>
          userAllowedModules.indexOf('admin.asset_management') >= 0,
        isModuleAvailable: (allAvailableModule) =>
          allAvailableModule.indexOf(Constants.ASSET) >= 0,
      },
      {
        key: 'product-type',
        title: __rootTc('product_type', 2),
        disable: false,
        action: moduleManager.getModuleRoute(
          'asset-management',
          'product-type'
        ),
        isModuleEnabledForUser: (userAllowedModules) =>
          userAllowedModules.indexOf('admin.asset_management') >= 0,
        isModuleAvailable: (allAvailableModule) =>
          allAvailableModule.indexOf(Constants.ASSET) >= 0,
      },
      {
        key: 'manufacturer-catalog',
        title: __rootTc('manufacturer_catalog'),
        disable: false,
        action: moduleManager.getModuleRoute(
          'asset-management',
          'manufacturer-catalog'
        ),
        isModuleEnabledForUser: (userAllowedModules) =>
          userAllowedModules.indexOf('admin.asset_catalogs') >= 0,
        isModuleAvailable: (allAvailableModule) =>
          allAvailableModule.indexOf(Constants.ASSET) >= 0,
      },
      {
        key: 'product-catalog',
        title: __rootTc('product_catalog'),
        disable: false,
        action: moduleManager.getModuleRoute(
          'asset-management',
          'product-catalog'
        ),
        isModuleEnabledForUser: (userAllowedModules) =>
          userAllowedModules.indexOf('admin.asset_catalogs') >= 0,
        isModuleAvailable: (allAvailableModule) =>
          allAvailableModule.indexOf(Constants.ASSET) >= 0,
      },
      {
        key: 'product-custom-fields',
        title: `${__rootTc('product')} ${__rootT('custom_fields')}`,
        disable: false,
        action: moduleManager.getModuleRoute('form', undefined, {
          params: { moduleName: Constants.PRODUCT },
        }),
        isModuleEnabledForUser: (userAllowedModules) =>
          userAllowedModules.indexOf('admin.asset_catalogs') >= 0,
        isModuleAvailable: (allAvailableModule) =>
          allAvailableModule.indexOf(Constants.ASSET) >= 0,
      },
      {
        key: 'vendor-catalog',
        title: __rootTc('vendor_catalog'),
        disable: false,
        action: moduleManager.getModuleRoute(
          'asset-management',
          'vendor-catalog'
        ),
        isModuleEnabledForUser: (userAllowedModules) =>
          userAllowedModules.indexOf('admin.asset_catalogs') >= 0,
        isModuleAvailable: (allAvailableModule) =>
          allAvailableModule.indexOf(Constants.ASSET) >= 0,
      },
      {
        key: 'vendpr-custom-fields',
        title: `${__rootTc('vendor')} ${__rootT('custom_fields')}`,
        disable: false,
        action: moduleManager.getModuleRoute('form', undefined, {
          params: { moduleName: Constants.VENDOR },
        }),
        isModuleEnabledForUser: (userAllowedModules) =>
          userAllowedModules.indexOf('admin.asset_catalogs') >= 0,
        isModuleAvailable: (allAvailableModule) =>
          allAvailableModule.indexOf(Constants.ASSET) >= 0,
      },
      {
        key: 'barcode',
        title: `${__rootTc('barcode')}`,
        disable: false,
        action: moduleManager.getModuleRoute('asset-management', 'barcode'),
        isModuleEnabledForUser: (userAllowedModules) =>
          userAllowedModules.indexOf('admin.asset_management') >= 0,
        isModuleAvailable: (allAvailableModule) =>
          allAvailableModule.indexOf(Constants.ASSET) >= 0,
      },
      {
        key: 'ip-range-location',
        title: `${__rootTc('ip_range_location')}`,
        disable: false,
        action: moduleManager.getModuleRoute(
          'asset-management',
          'ipRangeLocation'
        ),
        isModuleEnabledForUser: (userAllowedModules) =>
          userAllowedModules.indexOf('admin.asset_discovery') >= 0,
        isModuleAvailable: (allAvailableModule) =>
          allAvailableModule.indexOf(Constants.ASSET) >= 0,
      },
      {
        title: `${__rootTc('asset_movement', 2)}`,
        disable: false,
        action: moduleManager.getModuleRoute(
          'asset-management',
          'asset-movement'
        ),
        isModuleEnabledForUser: (userAllowedModules) =>
          userAllowedModules.indexOf('admin.asset_management') >= 0,
        isModuleAvailable: (allAvailableModule) =>
          allAvailableModule.indexOf(Constants.ASSET) >= 0,
      },
      {
        title: `${__rootTc('manage_baseline', 2)}`,
        disable: false,
        action: moduleManager.getModuleRoute(
          'asset-management',
          'manage-baseline'
        ),
        isModuleEnabledForUser: (userAllowedModules) =>
          userAllowedModules.indexOf('admin.asset_management') >= 0,
        isModuleAvailable: (allAvailableModule) =>
          allAvailableModule.indexOf(Constants.ASSET) >= 0,
      },
      {
        key: 'wake-on-lan',
        title: __rootTc('wake_on_lan'),
        disable: false,
        action: moduleManager.getModuleRoute('asset-management', 'wake-on-lan'),
        isModuleEnabledForUser: (userAllowedModules) =>
          userAllowedModules.indexOf('admin.asset_management') >= 0,
        isModuleAvailable: (allAvailableModule) =>
          allAvailableModule.indexOf(Constants.ASSET) >= 0,
      },
      {
        key: 'asset-configuration',
        title: __rootTc('asset_configuration'),
        disable: false,
        action: moduleManager.getModuleRoute(
          'asset-management',
          'asset-configuration'
        ),
        isModuleEnabledForUser: (userAllowedModules) =>
          userAllowedModules.indexOf('admin.asset_management') >= 0,
        isModuleAvailable: (allAvailableModule) =>
          allAvailableModule.indexOf(Constants.ASSET) >= 0,
      },
      {
        key: 'deployment-policy',
        title: `${__rootTc('deployment_policy', 2)}`,
        action: moduleManager.getModuleRoute(
          'asset-management',
          'deployment-policy'
        ),
        disable: false,
        isModuleEnabledForUser: (userAllowedModules) =>
          userAllowedModules.indexOf('admin.asset_management') >= 0,
        isModuleAvailable: (allAvailableModule) =>
          allAvailableModule.indexOf(Constants.ASSET) >= 0 &&
          allAvailableModule.indexOf(Constants.PATCH) === -1,
      },
    ],
  },
  {
    key: 'contract_management',
    heading: `${__rootTc('contract')} ${__rootT('management')}`,
    iconProps: {
      name: 'handshake',
    },
    isModuleEnabledForUser(userAllowedModules) {
      return userAllowedModules.indexOf('admin.contract_management') >= 0
    },
    isModuleAvailable: (allAvailableModule) =>
      allAvailableModule.indexOf(Constants.CONTRACT) >= 0,
    items: [
      {
        key: 'contract-type',
        title: `${__rootTc('contract_type', 2)}`,
        disable: false,
        action: moduleManager.getModuleRoute(
          'contract-management',
          'contract-types'
        ),
      },
      {
        key: 'contract-custom-fields',
        title: `${__rootTc('contract')} ${__rootT('custom_fields')}`,
        disable: false,
        action: moduleManager.getModuleRoute('form', undefined, {
          params: { moduleName: 'contract' },
        }),
      },
    ],
  },
  {
    key: 'purchase_management',
    heading: `${__rootTc('purchase')} ${__rootT('management')}`,
    // colSize: 6,
    // splitItems: 2,
    iconProps: {
      name: 'shopping-cart',
    },
    isModuleEnabledForUser(userAllowedModules) {
      return userAllowedModules.indexOf('admin.purchase_management') >= 0
    },
    isModuleAvailable: (allAvailableModule) =>
      allAvailableModule.indexOf(Constants.PURCHASE) >= 0,
    items: [
      {
        key: 'purchase-custom-fields',
        title: `${__rootTc('purchase')} ${__rootT('custom_fields')}`,
        disable: false,
        action: moduleManager.getModuleRoute('form', undefined, {
          params: { moduleName: Constants.PURCHASE },
        }),
      },
      {
        key: 'purchase-price-fields',
        title: `${__rootTc('purchase')} ${__rootT('price_fields')}`,
        disable: false,
        action: moduleManager.getModuleRoute('form', undefined, {
          params: { moduleName: Constants.PURCHASE },
          query: { fieldType: 'price' }, // do not change query fieldType
        }),
      },
      {
        key: 'gl-code',
        title: `${__rootTc('gl_code')}`,
        disable: false,
        action: moduleManager.getModuleRoute('purchase-management', 'gl-code'),
      },
      {
        key: 'cost-center',
        title: `${__rootTc('cost_center')}`,
        disable: false,
        action: moduleManager.getModuleRoute(
          'purchase-management',
          'cost-center'
        ),
      },
      {
        key: 'purchase-custom-rules',
        title: `${__rootTc('purchase')} ${__rootTc('custom_rules')}`,
        action: moduleManager.getModuleRoute('custom-rules', undefined, {
          params: { moduleName: Constants.PURCHASE },
          query: { type: Constants.PURCHASE },
        }),
        disable: false,
      },
    ],
  },
  {
    key: 'patch_management',
    heading: __rootT('patch_management'),
    iconProps: {
      name: 'shield-alt',
    },
    isModuleEnabledForUser(userAllowedModules) {
      return (
        userAllowedModules.indexOf('admin.patch_management') >= 0 ||
        userAllowedModules.indexOf('admin.deployment') >= 0
      )
    },
    isModuleAvailable: (allAvailableModule) =>
      allAvailableModule.indexOf(Constants.PATCH) >= 0 ||
      allAvailableModule.indexOf(Constants.DEPLOYMENT) >= 0,
    items: [
      {
        key: 'patch-settings',
        title: `${__rootTc('patch_settings')}`,
        action: moduleManager.getModuleRoute(
          'patch-management',
          'patch-settings'
        ),
        disable: false,
        keywords: [
          __rootTc('update_patch_database'),
          __rootTc('patch_storage_config'),
          __rootTc('patch_approval_policy'),
          `${__rootTc('deployment')} ${__rootTc('notification')}`,
        ],
        isModuleEnabledForUser: (userAllowedModules) =>
          userAllowedModules.indexOf('admin.patch_management') >= 0,
        isModuleAvailable: (allAvailableModule) =>
          allAvailableModule.indexOf(Constants.PATCH) >= 0,
      },
      {
        key: 'access-controls',
        title: `${__rootTc('access_controls')}`,
        action: moduleManager.getModuleRoute(
          'patch-management',
          'access-controls'
        ),
        disable: false,
        keywords: [
          `${__rootTc('proxy')} ${__rootTc('server')} ${__rootTc(
            'configuration'
          )}`,
          __rootTc('bandwidth_utilization'),
          __rootTc('relay_server_settings'),
        ],
        isModuleEnabledForUser: (userAllowedModules) =>
          userAllowedModules.indexOf('admin.patch_management') >= 0,
        isModuleAvailable: (allAvailableModule) =>
          allAvailableModule.indexOf(Constants.PATCH) >= 0,
      },
      {
        key: 'remote-office',
        title: `${__rootTc('remote_office', 2)}`,
        action: moduleManager.getModuleRoute(
          'patch-management',
          'remote-office'
        ),
        disable: false,
        isModuleEnabledForUser: (userAllowedModules) =>
          userAllowedModules.indexOf('admin.patch_management') >= 0,
        isModuleAvailable: (allAvailableModule) =>
          allAvailableModule.indexOf(Constants.PATCH) >= 0,
      },
      {
        key: 'endpoints-scope',
        title: `${__rootTc('endpoints')} ${__rootTc('scope')}`,
        action: moduleManager.getModuleRoute(
          'patch-management',
          'endpoints-scope'
        ),
        disable: false,
        isModuleEnabledForUser: (userAllowedModules) =>
          userAllowedModules.indexOf('admin.patch_management') >= 0,
        isModuleAvailable: (allAvailableModule) =>
          allAvailableModule.indexOf(Constants.PATCH) >= 0,
      },
      {
        key: 'computer-group',
        title: `${__rootTc('computer')} ${__rootTc('group', 2)}`,
        action: moduleManager.getModuleRoute(
          'patch-management',
          'computer-groups'
        ),
        disable: false,
        isModuleEnabledForUser: (userAllowedModules) =>
          userAllowedModules.indexOf('admin.patch_management') >= 0,
        isModuleAvailable: (allAvailableModule) =>
          allAvailableModule.indexOf(Constants.PATCH) >= 0,
      },
      {
        key: 'system-health-settings',
        title: `${__rootTc('system_health_settings')}`,
        action: moduleManager.getModuleRoute(
          'patch-management',
          'system-health-settings'
        ),
        disable: false,
        isModuleEnabledForUser: (userAllowedModules) =>
          userAllowedModules.indexOf('admin.patch_management') >= 0,
        isModuleAvailable: (allAvailableModule) =>
          allAvailableModule.indexOf(Constants.PATCH) >= 0,
      },
      {
        key: 'deployment-policy',
        title: `${__rootTc('deployment_policy', 2)}`,
        action: moduleManager.getModuleRoute(
          'patch-management',
          'deployment-policy'
        ),
        disable: false,
        isModuleEnabledForUser: (userAllowedModules) =>
          userAllowedModules.indexOf('admin.patch_management') >= 0,
        isModuleAvailable: (allAvailableModule) =>
          allAvailableModule.indexOf(Constants.PATCH) >= 0,
      },
      {
        key: 'decline-patch',
        title: `${__rootTc('decline_patch', 2)}`,
        action: moduleManager.getModuleRoute(
          'patch-management',
          'decline-patch'
        ),
        disable: false,
        isModuleEnabledForUser: (userAllowedModules) =>
          userAllowedModules.indexOf('admin.patch_management') >= 0,
        isModuleAvailable: (allAvailableModule) =>
          allAvailableModule.indexOf(Constants.PATCH) >= 0,
      },
      {
        key: 'packages',
        title: `${__rootTc('packages', 2)}`,
        action: moduleManager.getModuleRoute('packages'),
        disable: false,
        isModuleEnabledForUser: (userAllowedModules) =>
          userAllowedModules.indexOf('admin.deployment') >= 0,
        isModuleAvailable: (allAvailableModule) =>
          allAvailableModule.indexOf(Constants.DEPLOYMENT) >= 0,
      },
      {
        key: 'registry-template',
        title: `${__rootTc('registry_template', 2)}`,
        action: moduleManager.getModuleRoute('registry-template'),
        disable: false,
        isModuleEnabledForUser: (userAllowedModules) =>
          userAllowedModules.indexOf('admin.deployment') >= 0,
        isModuleAvailable: (allAvailableModule) =>
          allAvailableModule.indexOf(Constants.DEPLOYMENT) >= 0,
      },
      {
        key: 'agent-nomination',
        title: `${__rootTc('agent_nomination')}`,
        action: moduleManager.getModuleRoute(
          'patch-management',
          'agent-nomination'
        ),
        disable: false,
        isModuleEnabledForUser: (userAllowedModules) =>
          userAllowedModules.indexOf('admin.patch_management') >= 0,
        isModuleAvailable: (allAvailableModule) =>
          allAvailableModule.indexOf(Constants.PATCH) >= 0,
      },
    ],
  },
  {
    key: 'project_management',
    heading: __rootT('project_management'),
    iconProps: {
      name: 'project-diagram',
    },
    isModuleEnabledForUser(userAllowedModules) {
      return userAllowedModules.indexOf('admin.project_management') >= 0
    },
    isModuleAvailable: (allAvailableModule) =>
      allAvailableModule.indexOf(Constants.PROJECT) >= 0,
    items: [
      {
        key: 'project-form',
        title: `${__rootTc('project')} ${__rootT('form')}`,
        action: moduleManager.getModuleRoute('form', undefined, {
          params: { moduleName: 'project' },
        }),
        disable: false,
      },
      {
        key: 'project-risk',
        title: `${__rootTc('project_risk')}`,
        disable: false,
        action: moduleManager.getModuleRoute('project-management', 'risk-type'),
      },
      {
        key: 'project-type',
        title: `${__rootTc('project_type', 2)}`,
        disable: false,
        action: moduleManager.getModuleRoute(
          'project-management',
          'project-type'
        ),
      },
      {
        key: 'project-custom-rules',
        title: `${__rootTc('project')} ${__rootTc('custom_rules')}`,
        action: moduleManager.getModuleRoute('custom-rules', undefined, {
          params: { moduleName: 'project' },
          query: { type: 'project' },
        }),
        disable: false,
      },
    ],
  },
  {
    key: 'knowledge_management',
    heading: __rootT('knowledge_management'),
    iconProps: {
      name: 'lightbulb-on',
    },
    isModuleEnabledForUser(userAllowedModules) {
      return userAllowedModules.indexOf('admin.knowledge_management') >= 0
    },
    items: [
      {
        key: 'knowledge-custom-rules',
        title: `${__rootTc('knowledge')} ${__rootTc('custom_rules')}`,
        action: moduleManager.getModuleRoute('custom-rules', undefined, {
          params: { moduleName: Constants.KNOWLEDGE },
          query: { type: Constants.KNOWLEDGE },
        }),
        disable: false,
      },
    ],
  },
  {
    key: 'user_survey',
    heading: __rootTc('user_survey'),
    iconProps: {
      name: 'address-card',
    },
    isModuleEnabledForUser(userAllowedModules) {
      return userAllowedModules.indexOf('admin.user_survey_management') >= 0
    },
    items: [
      {
        key: 'user-survey',
        title: `${__rootTc('user_survey', 2)}`,
        action: moduleManager.getModuleRoute('user-survey'),
        disable: false,
      },
      {
        key: 'schedule-survey',
        title: `${__rootTc('schedule_survey')}`,
        action: moduleManager.getModuleRoute('user-survey', 'schedule-survey'),
        disable: false,
      },
    ],
  },
]
