<template>
  <div class="single-card flex-grow">
    <slot name="heading" />
    <h4 class="flex items-center text-primary">
      <MIcon v-bind="moduleItem.iconProps" class="text-base" size="2x" />
      <span class="mx-3">
        {{ moduleItem.heading }}
      </span>
    </h4>
    <MDivider class="mb-2" />
    <MRow>
      <MCol v-for="(chunk, index) in items" :key="index" class="flex">
        <MRow>
          <MCol>
            <MarkedContent
              v-for="item in chunk"
              :key="item.title"
              :text="highlightTerm"
            >
              <div :id="item.key" class="text-base cursor-pointer my-3 flex-1">
                <FlotoLink
                  v-if="item.action"
                  :to="item.action"
                  class="item"
                  :class="{
                    active: filteredKeywords(item.keywords),
                  }"
                >
                  {{ item.title }}
                </FlotoLink>
                <template
                  v-else
                  :class="{
                    active: filteredKeywords(item.keywords),
                  }"
                >
                  {{ item.title }}
                </template>
              </div>
            </MarkedContent>
          </MCol>
        </MRow>
      </MCol>
    </MRow>
  </div>
</template>

<script>
import { searchList } from '@utils/arr'
import Chunk from 'lodash/chunk'
import { LicenseComputed } from '@state/modules/license'
import { PreferenceComputed } from '@state/modules/preference'
import { OrganizationComputed } from '@state/modules/organization'
import MarkedContent from './marked-content'

export default {
  components: { MarkedContent },
  props: {
    moduleItem: { type: Object, required: true },
    highlightTerm: { type: String, default: undefined },
    // eslint-disable-next-line
    useSplitItem: { type: Boolean, default: true },
  },
  computed: {
    ...LicenseComputed,
    ...PreferenceComputed,
    ...OrganizationComputed,
    items() {
      const allItems = this.moduleItem.items.filter((m) => {
        if (m.isModuleAvailable) {
          return m.isModuleAvailable(this.availableModulesInLicense)
        }
        if (m.isModuleEnabledForUser) {
          return m.isModuleEnabledForUser(this.myAllowedModules)
        }
        if (m.multiTenantEnabled) {
          return this.multiTenantEnabled
        }
        if (m.mspEnabled) {
          return this.mspEnabled
        }
        return true
      })
      const itemLength = (allItems || []).length
      if (this.useSplitItem && this.moduleItem.splitItems) {
        const chunkIdentifire = Math.round(
          itemLength / this.moduleItem.splitItems
        )
        return Chunk(allItems, chunkIdentifire)
      }
      // return allItems
      return Chunk(allItems, itemLength)
    },
  },
  methods: {
    filteredKeywords(keywords) {
      if (keywords) {
        const filteredKeywords = searchList(
          keywords,
          this.highlightTerm,
          undefined
        )
        return !!filteredKeywords.length
      }
    },
  },
}
</script>

<style lang="less" scoped>
.single-card {
  background-color: var(--page-background-color);
  border-color: var(--border-color);

  @apply border border-solid rounded shadow p-5 mb-2 ml-2;

  & .item {
    color: var(--page-text-color);

    &.active {
      color: var(--secondary-orange);
    }
  }
}
</style>
