<template>
  <FieldContainer
    :field="field"
    :section-id="sectionId"
    v-bind="containerAttrs"
    v-on="containerListeners"
  >
    <FlotoFormItem
      :label="field.title"
      :rules="
        ((!isPortalLogin && field.required) ||
          (isPortalLogin && field.requesterRequired)) &&
        !disabled
          ? 'required'
          : undefined
      "
    >
      <FlotoAttachment
        v-model="currentValue"
        :button-text="field.attributes.buttonText"
        v-bind="attrs"
        :preview="mode === 'builder'"
        :disabled="disabled || mode === 'builder'"
        v-on="listeners"
      />
    </FlotoFormItem>
  </FieldContainer>
</template>

<script>
import FormField from '../../mixins/form-field'

export default {
  name: 'Attachment',
  mixins: [FormField],
}
</script>
