<template>
  <div
    data-field-type="section"
    class="section w-full"
    :class="{
      'sortable-chosen': currentEditingItem.guid === field.guid,
      builder: mode === 'builder',
    }"
  >
    <MDivider />
    <div class="section-header">
      <div class="section-name">
        <span>{{ field.title }}</span>
        <ActionButtons
          v-if="mode === 'builder'"
          :allow-split-field="false"
          :blocked-btns="['duplicate']"
          @edit="$emit('field-edit')"
          @remove="$emit('field-remove')"
        />
      </div>
    </div>
    <div class="section-body">
      <slot />
    </div>
    <MDivider />
  </div>
</template>

<script>
import ActionButtons from '../action-buttons/buttons'

export default {
  name: 'Section',
  components: { ActionButtons },
  props: {
    field: { type: Object, required: true },
    mode: { type: String, default: 'builder' },
  },
  computed: {
    currentEditingItem() {
      return this.$attrs['current-editing-item'] || {}
    },
  },
}
</script>

<style lang="less" scoped>
.section {
  &.builder {
    @apply border-dashed rounded border border-neutral-lighter border-t-0 border-b-0;
  }
  .section-header {
    .section-name {
      @apply flex justify-between mb-2 px-4 py-1 text-lg text-primary;
    }
  }
  .section-body {
    min-height: 4rem;

    @apply p-2;
  }
}
</style>
