import { getRootPluaralTranslator } from '@utils/get-module-translator'
const __rootTc = getRootPluaralTranslator()

export const depreciationDerivationOptions = () => [
  {
    text: __rootTc('product_level'),
    key: 'product_level',
    value: 'product_level',
  },
  {
    text: __rootTc('asset_level'),
    key: 'asset_level',
    value: 'asset_level',
  },
  {
    text: __rootTc('do_not_deprecate'),
    key: 'do_not_deprecate',
    value: 'do_not_deprecate',
  },
]

export const depreciationMethodOptions = () => [
  {
    text: __rootTc('none'),
    key: 'none',
    value: 'none',
  },
  {
    text: __rootTc('straight_line'),
    key: 'straight_line',
    value: 'straight_line',
  },
  {
    text: __rootTc('declining_balance'),
    key: 'declining_balance',
    value: 'declining_balance',
  },
  {
    text: __rootTc('sum_of_the_years_digit'),
    key: 'sum_of_the_years_digit',
    value: 'sum_of_the_years_digit',
  },
  {
    text: __rootTc('double_declining_balance'),
    key: 'double_declining_balance',
    value: 'double_declining_balance',
  },
]

export const depreciationTypeOptions = () => [
  {
    text: __rootTc('useful_life'),
    key: 'useful_life',
    value: 'useful_life',
  },
  {
    text: `${__rootTc('depreciation')} ${__rootTc('percentage')}`,
    key: 'percentage',
    value: 'percentage',
  },
]

export const costFactorOptions = () => [
  {
    text: __rootTc('purchase'),
    key: 'purchase',
    value: 'purchase',
  },
  {
    text: __rootTc('operational'),
    key: 'operational',
    value: 'operational',
  },
  {
    text: __rootTc('disposal'),
    key: 'disposal',
    value: 'disposal',
  },
  {
    text: __rootTc('others'),
    key: 'others',
    value: 'others',
  },
]
