<template>
  <FlotoModule>
    <RouterView :key="$route.fullPath" />
  </FlotoModule>
</template>

<script>
import Constants from '@constants'
import { PageMethods } from '@state/modules/page'
import { CustomRulesMethods } from '@state/modules/custom-rules'
import { LicenseComputed } from '@state/modules/license'
import { PreferenceComputed } from '@state/modules/preference'
export default {
  name: 'AssetModule',
  page() {
    return {
      title: this.$t('asset'),
    }
  },
  beforeRouteEnter(to, from, next) {
    if (to.name.indexOf('create') >= 0) {
      return next((vm) => {
        if (vm.availableModulesInLicense.indexOf(Constants.ASSET) === -1) {
          return vm.$router.replace({ name: 'upgrade-plan' })
        }
        if (vm.myAllowedModules.indexOf(Constants.ASSET) === -1) {
          return vm.$router.replace({ name: '404' })
        }
      })
    }
    if (
      [
        Constants.ASSET_HARDWARE,
        Constants.ASSET_SOFTWARE,
        Constants.ASSET_NON_IT,
      ].indexOf(to.params.assetType) === -1
    ) {
      return next({ name: '404' })
    }
    // vm is this
    next((vm) => {
      if (vm.availableModulesInLicense.indexOf(Constants.ASSET) === -1) {
        return vm.$router.replace({ name: 'upgrade-plan' })
      }
      if (vm.myAllowedModules.indexOf(Constants.ASSET) === -1) {
        return vm.$router.replace({ name: '404' })
      }
    })
  },
  beforeRouteLeave(to, from, next) {
    // add any module level clean up logic here
    setTimeout(() => {
      if (this[`${from.params.assetType}PageReset`]) {
        this[`${from.params.assetType}PageReset`]()
      }
    }, 400)
    next()
  },
  computed: {
    ...LicenseComputed,
    ...PreferenceComputed,
  },
  watch: {
    '$route.params.assetType': function (newValue, prevValue) {
      if (this[`${prevValue}PageReset`]) {
        this[`${prevValue}PageReset`]()
      }
    },
  },
  methods: {
    ...PageMethods,
    ...CustomRulesMethods,
  },
}
</script>
