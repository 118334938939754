<template>
  <FieldContainer
    :field="field"
    :section-id="sectionId"
    v-bind="containerAttrs"
    v-on="containerListeners"
  >
    <FlotoFormItem
      v-if="disabled"
      :label="label"
      :type="field.inputType"
      v-bind="attrs"
      v-on="listeners"
    >
      {{ currentValue || '---' }}
    </FlotoFormItem>
    <FlotoFormItem v-else :label="label" :rules="validationsRules">
      <ExtendedDropdown
        v-model="currentValue"
        searchable
        :disabled="
          disabled ||
            mode === 'builder' ||
            field.attributes.systemManagedField ||
            isDisable ||
            !dynamicFieldOptions
        "
        :read-only="readOnly"
        :class="{
          block: true,
          'w-full': field.attributes.widthClass !== 'w-full',
          'w-1/2': field.attributes.widthClass === 'w-full',
        }"
        v-bind="attrs"
        :options="options"
        as-input
        v-on="listeners"
      />
    </FlotoFormItem>
  </FieldContainer>
</template>

<script>
import _baseDropdownPicker from '@components/_base-dropdown-picker.vue'
import FormField from '../../mixins/form-field'

const ExtendedDropdown = {
  name: 'ExtendedDropdown',
  extends: _baseDropdownPicker,
  data() {
    this.unassignedValue = ''
    return {}
  },
}

export default {
  name: 'Dynamic',
  components: { ExtendedDropdown },
  mixins: [FormField],
  props: {
    moduleName: { type: String, default: undefined },
    dynamicFieldOptions: { type: Array, default: undefined },
  },
  computed: {
    options() {
      return this.dynamicFieldOptions
        ? this.dynamicFieldOptions.map((o) => ({ key: o, value: o, text: o }))
        : this.currentValue
        ? [
            {
              key: this.currentValue,
              value: this.currentValue,
              text: this.currentValue,
            },
          ]
        : []
    },
    validationsRules() {
      const rules = {
        required: false,
      }
      return rules
    },
    label() {
      return this.field.title
    },
  },
}
</script>
