import lazyLoadView from '@router/lazy-loader'
import formRoutes from '@modules/form/form-routes'
import workflowRoutes from '@modules/workflow/workflow-routes'
import categoryRoutes from '@modules/category/category-routes'
import slaRoutes from '@modules/sla/sla-routes'
import AutoAssignmentRoutes from '@modules/auto-assignment/auto-assignment-routes'
import ScenarioRoutes from '@modules/scenario/scenario-routes'
import StatusRoutes from '@modules/status/status-routes'
import EmailNotificationRoutes from '@modules/email-notification/email-notification-routes'
import TemplatesRoutes from '@modules/templates/templates-routes'
import BusinessHoursRoutes from '@modules/business-hours/business-hours-routes'
import UserGroupsRoutes from '@modules/user-groups/user-groups-routes'
import UsersRoutes from '@modules/users/users-routes'
import RolesRoutes from '@modules/roles/roles-routes'
import OrganizationRoutes from '@modules/organization/organization-routes'
import ITInfraStructureRoutes from '@modules/it-infrastructure/it-infrastructure-routes'
import SupportConsoleRoutes from '@modules/support-console/support-console-routes'
import ResponseTemplateRoutes from '@modules/response-template/response-template-routes'
import ApprovalRoutes from '@modules/approval/approval-routes'
import CustomRulesRoutes from '@modules/custom-rules/custom-rules-routes'
import BusinessServiceRoutes from '@modules/business-service/business-service-routes'
import ChangeManagementRoutes from '@modules/change-management/change-management-routes'
import RequestManagementRoutes from '@modules/request-management/request-management-routes'
import AssetManagementRoutes from '@modules/asset-management/asset-management-routes'
import AutoTicketCreateRoutes from '@modules/auto-ticket-create/auto-ticket-create-routes'
import AutoTaskCreateRoutes from '@modules/auto-task-create/auto-task-create-routes'
import ContractManagementRoutes from '@modules/contract-management/contract-management-routes'
import Integration from '@modules/integration/integration-routes'
import EventNotificationsRoutes from '@modules/event-notifications/event-notifications-routes'
import ReleaseManagementRoutes from '@modules/release-management/release-management-routes'
import PurchaseManagementRoutes from '@modules/purchase-management/purchase-management-routes'
import ProjectManagementRoutes from '@modules/project-management/project-management-routes'
import UserSurveyRoutes from '@modules/user-survey/user-survey-routes'
import ContactopsRoutes from '@modules/contactops/contactops-routes'
import PatchManagementRoutes from '@modules/patch-management/patch-management-routes'
import PackagesRoutes from '@modules/packages/packages-routes'
import RegistryTemplateRoutes from '@modules/registry-template/registry-template-routes'
import FormRulesRoutes from '@modules/form-rules/form-rules-routes'
import SmartSuggestionsRoutes from '@modules/smart-suggestions/smart-suggestions-routes'
import ContainerView from './views/main'
import configs from './config'

const routePrefix = configs.routePrefix

const moduleName = configs.name

const routeNamePrefix = configs.routeNamePrefix

export default [
  {
    path: `/${routePrefix}`,
    component: ContainerView,
    redirect: `/${routePrefix}/`,
    meta: { moduleName, breadcrumbKey: 'admin' },
    children: [
      {
        path: '',
        name: routeNamePrefix,
        component: () => lazyLoadView(import('./views/list')),
        meta: {},
      },
      ...formRoutes,
      ...workflowRoutes,
      ...categoryRoutes,
      ...slaRoutes,
      ...AutoAssignmentRoutes,
      ...ScenarioRoutes,
      ...StatusRoutes,
      ...EmailNotificationRoutes,
      ...TemplatesRoutes,
      ...BusinessHoursRoutes,
      ...UserGroupsRoutes,
      ...UsersRoutes,
      ...RolesRoutes,
      ...OrganizationRoutes,
      ...ITInfraStructureRoutes,
      ...SupportConsoleRoutes,
      ...ResponseTemplateRoutes,
      ...ApprovalRoutes,
      ...CustomRulesRoutes,
      ...BusinessServiceRoutes,
      ...RequestManagementRoutes,
      ...AssetManagementRoutes,
      ...ChangeManagementRoutes,
      ...AutoTicketCreateRoutes,
      ...AutoTaskCreateRoutes,
      ...ContractManagementRoutes,
      ...Integration,
      ...ReleaseManagementRoutes,
      ...PurchaseManagementRoutes,
      ...ProjectManagementRoutes,
      ...EventNotificationsRoutes,
      ...UserSurveyRoutes,
      ...ContactopsRoutes,
      ...PatchManagementRoutes,
      ...PackagesRoutes,
      ...RegistryTemplateRoutes,
      ...FormRulesRoutes,
      ...SmartSuggestionsRoutes,
    ],
  },
]
