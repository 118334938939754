<template>
  <FieldContainer
    :field="field"
    :section-id="sectionId"
    v-bind="containerAttrs"
    v-on="containerListeners"
  >
    <FlotoFormItem v-if="disabled" :label="label">
      {{ currentValue || '---' }}
    </FlotoFormItem>
    <FlotoFormItem v-else :label="label" :rules="validationsRules">
      <ExtendedDropdown
        v-model="currentValue"
        :disabled="
          disabled ||
          mode === 'builder' ||
          field.attributes.systemManagedField ||
          isDisable
        "
        :read-only="readOnly"
        :class="{
          block: true,
          'w-full': field.attributes.widthClass !== 'w-full',
          'w-1/2': field.attributes.widthClass === 'w-full',
        }"
        :hidden-options-keys="hiddenOptionsKeys"
        :visible-options-keys="visibleOptionsKeys"
        v-bind="attrs"
        :options="field.options.map((o) => ({ key: o, value: o, text: o }))"
        as-input
        v-on="listeners"
      />
    </FlotoFormItem>
  </FieldContainer>
</template>

<script>
import _baseDropdownPicker from '@components/_base-dropdown-picker.vue'
import FormField from '../../mixins/form-field'

const ExtendedDropdown = {
  name: 'ExtendedDropdown',
  extends: _baseDropdownPicker,
  data() {
    this.unassignedValue = ''
    return {}
  },
}

export default {
  name: 'Dropdown',
  components: { ExtendedDropdown },
  mixins: [FormField],
  props: {
    moduleName: { type: String, default: undefined },
  },
  computed: {
    isRequired() {
      return (
        (!this.isPortalLogin && this.field.required) ||
        (this.isPortalLogin && this.field.requesterRequired) ||
        this.isMandatory
      )
    },
    validationsRules() {
      const rules = {
        required: this.isRequired || false,
      }
      // apply form rule action for mandatory non-mandatory
      // if (this.isRuleDirty) {
      //   rules.required = this.isMandatory || false
      // }
      // if field rule is disable then remove required validation
      if (this.isDisable) {
        rules.required = false
      }
      return rules
    },
    label() {
      return this.moduleName === this.$constants.USER_SURVEY ||
        this.moduleName === this.$constants.USER_SURVEY_SCHEDULE
        ? this.field.question
        : this.field.title
    },
  },
}
</script>
