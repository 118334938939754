<template>
  <FieldContainer
    :field="field"
    :section-id="sectionId"
    v-bind="containerAttrs"
    v-on="containerListeners"
  >
    <FlotoFormItem
      v-if="disabled"
      :label="label"
      :type="field.inputType"
      v-bind="attrs"
      v-on="listeners"
    >
      {{ currentValue || '---' }}
    </FlotoFormItem>
    <FlotoFormItem
      v-else
      v-model="currentValue"
      :disabled="
        mode === 'builder' || field.attributes.systemManagedField || isDisable
      "
      :label="label"
      :rules="validationsRules"
      :class="{
        'w-full':
          (field.inputType === 'number' || field.inputType === 'datetime') &&
          field.attributes.widthClass !== 'w-full',
        'w-1/4':
          (field.inputType === 'number' || field.inputType === 'datetime') &&
          field.attributes.widthClass === 'w-full',
      }"
      :type="field.inputType"
      v-bind="attrs"
      v-on="listeners"
    />
  </FieldContainer>
</template>

<script>
import FormField from '../../mixins/form-field'

export default {
  name: 'TextField',
  mixins: [FormField],
  props: {
    moduleName: { type: String, default: undefined },
  },
  computed: {
    isRequired() {
      return (
        (!this.isPortalLogin && this.field.required) ||
        (this.isPortalLogin && this.field.requesterRequired) ||
        this.isMandatory
      )
    },
    validationsRules() {
      const rules = {
        required: this.isRequired || false,
        subject: true,
      }
      // apply form rule action for mandatory non-mandatory
      // if (this.isRuleDirty) {
      //   rules.required = this.isMandatory || false
      // }
      // if field rule is disable then remove required validation
      if (this.isDisable) {
        rules.required = false
      }
      return rules
    },
    label() {
      return this.moduleName === this.$constants.USER_SURVEY ||
        this.moduleName === this.$constants.USER_SURVEY_SCHEDULE
        ? this.field.question
        : this.field.title
    },
    attrs() {
      const { widthClass, allowDecimal, allowTime, ...attrs } =
        this.field.attributes
      if (this.field.type === 'number') {
        attrs.precision = allowDecimal ? 2 : 0
      }
      if (this.field.type === 'datetime') {
        attrs.showTime = allowTime
      }
      return { ...attrs, ...this.$attrs }
    },
  },
}
</script>
