import {
  getRootPluaralTranslator,
  // getModuleTranslator,
} from '@utils/get-module-translator'
// import moduleConfig from '../config'
const __tc = getRootPluaralTranslator()
// const __mtc = getModuleTranslator(moduleConfig.translationKey)

export const osArchitectureOptions = () => [
  { text: 'All', key: 'all' },
  { text: '64 BIT', key: 'bit_64' },
  { text: '32 BIT', key: 'bit_32' },
  { text: 'ARM64', key: 'arm64' },
]
export const pcSystemTypeOptions = () => [
  { text: 'Unspecified', key: 'unspecified' },
  { text: 'Desktop', key: 'desktop' },
  { text: 'Mobile', key: 'mobile' },
  { text: 'Workstation', key: 'workstation' },
  { text: 'Enterprise Server', key: 'enterprise_server' },
  { text: 'SOHO Server', key: 'soho_server' },
  { text: 'Appliance PC', key: 'appliance_pc' },
  { text: 'Performance Server', key: 'performance_server' },
  { text: 'Maximum', key: 'maximum' },
]
export const deviceStatusOptions = () => [
  { text: 'Ok', key: 'ok' },
  { text: 'Error', key: 'error' },
  { text: 'Degraded', key: 'degraded' },
  { text: 'Unknown', key: 'unknown' },
  { text: 'Pred Fail', key: 'pred_fail' },
  { text: 'Starting', key: 'starting' },
  { text: 'Stopping', key: 'stopping' },
  { text: 'Service', key: 'service' },
  { text: 'Stressed', key: 'stressed' },
  { text: 'Non Recover', key: 'non_recover' },
  { text: 'Non Contact', key: 'no_contact' },
  { text: 'Lost Communication', key: 'lost_communication' },
  { text: 'Stopped', key: 'stopped' },
  { text: 'Running', key: 'running' },
]

export const driveTypeOptions = () => [
  { text: 'Unknown', key: 'unknown' },
  { text: 'No Root Directory', key: 'no_root_directory' },
  { text: 'Removable Disk', key: 'removable_disk' },
  { text: 'Local Disk', key: 'local_disk' },
  { text: 'Network Drive', key: 'network_drive' },
  { text: 'Compact Disc', key: 'compact_disc' },
  { text: 'Ram Disk', key: 'ram_disk' },
]

export const booleanOptions = () => [
  { text: __tc('yes'), key: true },
  { text: __tc('no'), key: false },
]

export const pointingTypeOptions = () => [
  { text: 'Other', key: 'other' },
  { text: 'Unknown', key: 'unknown' },
  { text: 'Mouse', key: 'mouse' },
  { text: 'Track Ball', key: 'track_ball' },
  { text: 'Rack Point', key: 'rack_point' },
  { text: 'Glide Point', key: 'glide_point' },
  { text: 'Touch Pad', key: 'touch_pad' },
  { text: 'Touch Screen', key: 'touch_screen' },
  {
    text: 'Mouse Optical Sensor',
    key: 'mouse_optical_sensor',
  },
]

export const connectionStatusOptions = () => [
  { text: 'Disconnected', key: 'disconnected' },
  { text: 'Connecting', key: 'connecting' },
  { text: 'Connected', key: 'connected' },
  { text: 'Disconnecting', key: 'disconnecting' },
  {
    text: 'Hardware Not Present',
    key: 'hardware_not_present',
  },
  { text: 'Hardware Disabled', key: 'hardware_disabled' },
  {
    text: 'Hardware Malfunction',
    key: 'hardware_malfunctino',
  },
  { text: 'Media Disconnected', key: 'media_disconnected' },
  { text: 'authenticating', key: 'authenticating' },
  {
    text: 'Authentication Succeeded',
    key: 'authentication_succeeded',
  },
  {
    text: 'Authentication Failed',
    key: 'authentication_failed',
  },
  { text: 'Invalid Address', key: 'invalid_address' },
  { text: 'Other', key: 'other' },
]
export const activationStatusOptions = () => [
  { text: 'Licensed', key: 'licensed' },
  { text: 'Unlicensed', key: 'unlicensed' },
  { text: 'OOB Grace', key: 'oobgrace' },
  { text: 'OOT Grace', key: 'ootgrace' },
  { text: 'Non Genuine Grace', key: 'nongenuinegrace' },
  { text: 'Notification', key: 'notification' },
  { text: 'Extended Grace', key: 'extendedgrace' },
]
export const userAccountTypeOptions = () => [
  {
    text: 'Temporary Duplicate Account',
    key: 'temporary_duplicate_account',
  },
  { text: 'Normal Account', key: 'normal_account' },
  {
    text: 'Interdomain Trust Account',
    key: 'interdomain_trust_account',
  },
  {
    text: 'Workstation Trust Account',
    key: 'workstation_trust_account',
  },
  {
    text: 'Server Trust Account',
    key: 'server_trust_account',
  },
]

export const memoryTypeOptions = () => [
  { text: 'Unknown', key: 'unknown' },
  { text: 'Other', key: 'other' },
  { text: 'DRAM', key: 'dram' },
  { text: 'Synchronous DRAM', key: 'synchronous_dram' },
  { text: 'Cache DRAM', key: 'cache_dram' },
  { text: 'EDO', key: 'edo' },
  { text: 'EDRAM', key: 'edram' },
  { text: 'VRAM', key: 'vram' },
  { text: 'SRAM', key: 'sram' },
  { text: 'RAM', key: 'ram' },
  { text: 'ROM', key: 'rom' },
  { text: 'FLASH', key: 'flash' },
  { text: 'EEPROM', key: 'eeprom' },
  { text: 'FEPROM', key: 'feprom' },
  { text: 'EPROM', key: 'eprom' },
  { text: 'CDRAM', key: 'cdram' },
  { text: 'RAM 3D', key: 'ram_3d' },
  { text: 'SDRAM', key: 'sdram' },
  { text: 'SGRAM', key: 'sgram' },
  { text: 'RDRAM', key: 'rdram' },
  { text: 'DDR', key: 'ddr' },
  { text: 'DDR2', key: 'ddr2' },
  { text: 'DDR2 FB DIMM', key: 'ddr2_fb_dimm' },
  { text: 'DDR3', key: 'ddr3' },
  { text: 'DDR4', key: 'ddr4' },
  { text: 'FBD2', key: 'fbd2' },
  { text: 'LPDDR4', key: 'lpddr4' },
]

export const varianceHistoryStatusOptions = () => [
  { text: 'Unapproved', key: 'unapproved' },
  { text: 'Approved', key: 'approved' },
  { text: 'Rejected Pending', key: 'rejected_pending' },
  { text: 'Rejected', key: 'rejected' },
]

export const assetConditionOptions = () => [
  { text: 'None', key: 'none' },
  { text: 'Good', key: 'good' },
  { text: 'Faulty', key: 'faulty' },
  { text: 'Non Repairable', key: 'non_repairable' },
]

export const softwareUninstallationTaskStatusOptions = () => [
  { text: 'Detected', key: 'detected' },
  { text: 'Completed', key: 'completed' },
  { text: 'Failed', key: 'failed' },
  { text: 'Cancelled', key: 'cancelled' },
]

export const mobileDeviceTypeOptions = () => [
  { text: __tc('smartphone'), key: 'smartphone' },
  { text: __tc('tablet'), key: 'tablet' },
]

export const usbComponentStatusOptions = () => [
  { text: 'Ok', key: 'ok' },
  { text: 'Error', key: 'error' },
  { text: 'Degraded', key: 'degraded' },
  { text: 'Unknown', key: 'unknown' },
  { text: 'Pred Fail', key: 'pred_fail' },
  { text: 'Starting', key: 'starting' },
  { text: 'Stopping', key: 'stopping' },
  { text: 'Service', key: 'service' },
  { text: 'Stressed', key: 'stressed' },
  { text: 'Non Recover', key: 'non_recover' },
  { text: 'No Contact', key: 'no_contact' },
  { text: 'Lost Ccommunication', key: 'lost_communication' },
  { text: 'Mounted', key: 'mounted' },
  { text: 'Stopped', key: 'stopped' },
  { text: 'Running', key: 'running' },
]
