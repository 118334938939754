import Omit from 'lodash/omit'
import CloneDeep from 'lodash/cloneDeep'
import FindIndex from 'lodash/findIndex'
import { generateId } from '@utils/id'
import { move } from '@utils/arr'
import {
  getRootTranslator,
  getRootPluaralTranslator,
} from '@utils/get-module-translator'
import { transformFormFieldForServer } from '@data/form'
import Constants from '@constants'
import {
  updateFormFieldsApi,
  addFormFieldApi,
  removeFormFieldApi,
  updateFormFieldApi,
} from './form-api'

const __rootT = getRootTranslator()
const __rootTc = getRootPluaralTranslator()

const initialData = {
  loading: true,
  initialized: false,
  currentModule: null,
  parentResourceId: null,
  editingItemFactoryValue: null,
  currentActiveItem: null,
  currentActiveItemIndex: null,
  currentActiveItemSectionGuid: null,
  alreadyUsedSystemControls: [],
  availableControls: {
    elements: [
      {
        guid: generateId(),
        type: 'text',
        name: 'text_input',
        icon: 'textbox',
      },
      {
        guid: generateId(),
        type: 'textarea',
        name: 'text_area',
        icon: 'textbox',
      },
      {
        guid: generateId(),
        type: 'dropdown',
        name: 'dropdown',
        icon: 'dropdown',
      },
      {
        guid: generateId(),
        type: 'datetime',
        name: 'datetime',
        icon: 'datetime',
      },
      {
        guid: generateId(),
        type: 'number',
        name: 'number',
        icon: 'number',
      },
      {
        guid: generateId(),
        type: 'checkbox',
        name: 'checkbox',
        icon: 'checkbox',
      },
      {
        guid: generateId(),
        type: 'radio',
        name: 'radio',
        icon: 'radio',
      },
      {
        guid: generateId(),
        type: 'upload',
        name: 'attachment',
        icon: 'attachment',
      },
      {
        guid: generateId(),
        type: 'section',
        name: 'section',
        icon: 'section',
      },
      {
        guid: generateId(),
        type: 'label',
        name: 'label',
        icon: 'label',
      },
      {
        guid: generateId(),
        type: 'dependent',
        name: 'dependent',
        icon: 'dependent',
      },
      {
        guid: generateId(),
        type: 'api',
        name: 'api',
        icon: 'api',
      },
      {
        guid: generateId(),
        type: 'dynamic',
        name: 'dynamic',
        iconName: 'code',
      },
      {
        guid: generateId(),
        type: 'rating',
        name: 'rating',
        icon: 'rating',
      },
      {
        guid: generateId(),
        type: 'slider',
        name: 'numeric_scale',
        icon: 'slider',
      },
      {
        guid: generateId(),
        type: 'boolean',
        name: 'boolean',
        icon: 'boolean',
      },
    ],
    system: [],
  },
  currentFormElements: [],
  currentHoverActiveItemId: null,
  isDraggingField: false,
}

export const data = CloneDeep(initialData)

// const systemFieldsIconMap = {
//   urgency,
//   tags,
//   location,
//   description,
//   priority,
//   department,
//   impact,
//   requester_name: requesterName,
// }

export const methods = {
  setCurrentModule(moduleName) {
    data.currentModule = moduleName
  },

  setParentResourceId(id) {
    data.parentResourceId = id
  },

  init(fetchFn) {
    return fetchFn().then((controls) => {
      data.availableControls = {
        ...data.availableControls,
        // system: controls.map((c) => ({
        //   ...c,
        //   icon: systemFieldsIconMap[c.type] || 'question',
        // })),
      }
    })
  },

  setLoading(value) {
    data.loading = value
  },

  setInitialized(value) {
    data.initialized = value
  },

  /**
   * Set form elements
   * @param {Array} elements
   */
  setFormElements(elements) {
    data.currentFormElements = CloneDeep(elements)
  },

  setUsedSystemControls(controls) {
    data.alreadyUsedSystemControls = controls
  },

  /**
   * reset form data
   */
  reset() {
    Object.assign(data, CloneDeep(initialData))
  },

  onDraggingStarted() {
    data.isDraggingField = true
  },

  onDraggingEnded() {
    data.isDraggingField = false
  },

  /**
   * Set currently Active Item
   * @param {Object} element
   * @param {number} index
   * @param {undefined|string} sectionGuid
   */
  setCurrentActiveItem(element, index, sectionGuid) {
    if (!element.id) {
      // this is new element and not saved yet
      methods.addFormElement(element, index, sectionGuid)
    }
    // data.currentActiveItem = CloneDeep(element)
    data.currentActiveItem = element
    data.currentActiveItemIndex = index
    data.currentActiveItemSectionGuid = sectionGuid
    data.editingItemFactoryValue = CloneDeep(element)
  },

  /**
   * cancel recently added element due to required settings are not given in input
   */
  cancelAddingItem() {
    // if this is unsaved item
    if (!data.currentActiveItem.id) {
      methods.removeFormElement(
        data.currentActiveItem,
        data.currentActiveItemSectionGuid
      )
    } else {
      // reset Factory value and discard all changes
      methods.updateSingleField(
        data.editingItemFactoryValue,
        data.currentActiveItemSectionGuid
      )
    }
    methods.clearCurrentActiveItem()
  },

  /**
   * clear currently active item
   */
  clearCurrentActiveItem() {
    data.currentActiveItem = null
    data.currentActiveItemIndex = null
    data.currentActiveItemSectionGuid = null
    data.editingItemFactoryValue = null
  },

  /**
   * reorder all the fields from given index
   * @param {number} startIndex
   * @param {undefined|string} sectionGuid
   */
  reorderFields(startIndex, sectionGuid, addFactor = 1) {
    let fields
    if (sectionGuid) {
      fields = data.currentFormElements.find(
        (f) => f.guid === sectionGuid
      ).fields
    } else {
      fields = data.currentFormElements
    }
    const reorderedFields = fields.slice(startIndex)
    reorderedFields.forEach((f) => (f.order += addFactor))
    const updatedFields = {}
    reorderedFields.forEach((f) => {
      const t = transformFormFieldForServer(f)
      updatedFields[f.id] = {
        type: t.type,
        orderInField: t.orderInField,
      }
    })
    return updateFormFieldsApi(updatedFields, false)
  },

  /**
   * convert current active item to field if data is saved
   */
  convertCurrentActiveItemToField() {
    if (!data.currentActiveItem.id) {
      return addFormFieldApi(
        data.parentResourceId,
        transformFormFieldForServer(data.currentActiveItem)
      )
        .then((response) => {
          data.currentActiveItem.id = response.id
          delete data.currentActiveItem.duplicate
          // data.currentActiveItem.order = data.currentActiveItemIndex
          // new field is added so move all fields after added index to down ward by 1
          return methods.updateSingleField(
            data.currentActiveItem,
            data.currentActiveItemSectionGuid
          )
        })
        .then(() => {
          if (data.currentActiveItem.isSystemControl) {
            data.alreadyUsedSystemControls = [
              ...data.alreadyUsedSystemControls,
              data.currentActiveItem.type,
            ]
          }
          return methods.reorderFields(
            data.currentActiveItemIndex,
            data.currentActiveItemSectionGuid,
            0
          )
        })
    }
    return methods.updateSingleField(
      data.currentActiveItem,
      data.currentActiveItemSectionGuid,
      true
    )
  },

  /**
   * Add new control to the left side in system section
   * @param {Array} controls
   */
  setSystemControls(controls) {
    data.availableControls = { ...data.availableControls, system: controls }
  },

  /**
   * Add new control to the left side in elements sections
   * @param {Array} controls
   */
  setElementControls(controls) {
    data.availableControls = { ...data.availableControls, elements: controls }
  },

  /**
   * convert control to the formElement
   * @param {Object} control
   */
  convertControlToForm(control) {
    return {
      ...control,
      guid: generateId(),
      title: `${control.isSystemControl ? '' : __rootT('new')} ${
        control.isSystemControl
          ? __rootTc(control.name)
          : __rootTc(control.name)
      }`,
      ...(data.currentModule === Constants.USER_SURVEY
        ? {
            question: `${control.isSystemControl ? '' : __rootT('new')} ${
              control.isSystemControl
                ? __rootTc(control.name)
                : __rootTc(control.name)
            }`,
          }
        : {}),
      placeholder: `${control.isSystemControl ? '' : __rootT('new')} ${
        control.isSystemControl ? __rootT(control.name) : __rootTc(control.name)
      }`,
      canDelete: true,
      canUpdate: true,
      isSystemField: control.isSystemControl,
      attributes: {
        widthClass: 'w-full',
        // control.type === 'textarea' || control.type === 'description'
        //   ? 100
        //   : 40,
        ...(control.type === 'textarea' ? { rows: 5 } : {}),
        ...(control.type === 'datetime' ? { allowTime: false } : {}),
        ...(control.type === 'attachment'
          ? { buttonText: __rootTc('browse_file') }
          : {}),
        ...(control.type === 'api'
          ? { buttonText: __rootTc('fetch_data') }
          : {}),
        ...(control.type === 'dependent'
          ? { displayHierarchyInReport: true }
          : {}),
      },
      fields: [],
      defaultValue: undefined,
      requesterCanEdit: false,
      requesterRequired: false,
      inputType:
        ['text', 'number', 'datetime', 'textarea'].indexOf(control.type) >= 0
          ? control.type
          : control.isSystemControl
          ? control.type
          : undefined,
      ...(['dropdown', 'radio', 'checkbox'].indexOf(control.type) >= 0
        ? { options: [] }
        : {}),
      ...(control.type === 'checkbox' ? { defaultValue: [] } : {}),
      ...(control.type === 'api'
        ? { apiFieldDetails: { apiFieldType: 'simple', requestType: 'get' } }
        : {}),
      ...(control.type === 'dependent' ? { tree: [] } : {}),
    }
  },

  /**
   * Add new element at a given position
   * @param {Object} element
   * @param {Number} index
   * @param {undefined|string} sectionGuId
   * @param {boolean} syncToServer
   */
  addFormElement(element, index, sectionGuId, syncToServer) {
    // const previousIndex = index - 1
    // let previousElement
    let fields
    let sectionFields
    let sectionIndex
    if (sectionGuId) {
      sectionIndex = FindIndex(data.currentFormElements, {
        guid: sectionGuId,
      })
      if (sectionIndex !== -1) {
        sectionFields = data.currentFormElements[sectionIndex].fields
        // previousElement = sectionFields[previousIndex]
      }
    }
    //  else {
    // previousElement = data.currentFormElements[previousIndex]
    // }
    // if (
    //   previousElement &&
    //   previousElement.attributes.widthClass === 'w-1/2' &&
    //   element.type !== 'section'
    // ) {
    //   element.attributes.widthClass = 'w-1/2'
    // }
    if (sectionGuId) {
      Object.assign(element, {
        sectionId: data.currentFormElements[sectionIndex].id,
        useOnPortal: data.currentFormElements[sectionIndex].useOnPortal,
        requesterGroups: data.currentFormElements[sectionIndex].useOnPortal
          ? data.currentFormElements[sectionIndex].requesterGroups || []
          : [],
      })
      const updatedSectionField = {
        ...data.currentFormElements[sectionIndex],
        fields: [
          ...sectionFields.slice(0, index),
          element,
          ...sectionFields.slice(index),
        ],
      }
      updatedSectionField.fields.forEach((f, index) => (f.order = index + 1))
      fields = [
        ...data.currentFormElements.slice(0, sectionIndex),
        updatedSectionField,
        ...data.currentFormElements.slice(sectionIndex + 1),
      ].filter((f) => f.guid !== element.guid)
    } else {
      Object.assign(element, {
        sectionId: null,
      })
      fields = [
        ...data.currentFormElements.slice(0, index),
        element,
        ...data.currentFormElements.slice(index),
      ]
    }
    fields.forEach((f, index) => {
      f.order = index + 1
    })
    const oldFields = Object.freeze(data.currentFormElements)
    data.currentFormElements = fields
    if (syncToServer) {
      return updateFormFieldApi(transformFormFieldForServer(element))
        .then(() => methods.reorderFields(index, sectionGuId))
        .catch(() => (data.currentFormElements = oldFields))
    }
  },

  /**
   * Remove from element from a given position
   * @param {object} index
   * @param {undefined|string} sectionGuid
   * @param {boolean} syncToServer
   */
  removeFormElement(element, sectionGuid, syncToServer) {
    let index
    let updatedFields
    let spacerField
    if (sectionGuid) {
      index = FindIndex(data.currentFormElements, { guid: sectionGuid })
      if (index !== -1) {
        spacerField = data.currentFormElements[index].fields.find(
          (field) =>
            (field.attributes || {}).parentFieldId &&
            element.id &&
            String((field.attributes || {}).parentFieldId) ===
              String(element.id)
        )
        updatedFields = [
          ...data.currentFormElements.slice(0, index),
          {
            ...data.currentFormElements[index],
            fields: data.currentFormElements[index].fields
              .filter(
                (f) =>
                  f.guid !== element.guid &&
                  (spacerField ? spacerField.guid !== f.guid : true)
              )
              .map((f, index) => ({ ...f, order: index + 1 })),
          },
          ...data.currentFormElements.slice(index + 1),
        ]
      }
    } else {
      index = FindIndex(data.currentFormElements, { guid: element.guid })
      spacerField = data.currentFormElements.find(
        (field) =>
          (field.attributes || {}).parentFieldId &&
          element.id &&
          String((field.attributes || {}).parentFieldId) === String(element.id)
      )
      updatedFields = [
        ...data.currentFormElements.slice(0, index),
        ...data.currentFormElements.slice(index + 1),
      ]
        .filter((f) => (spacerField ? spacerField.guid !== f.guid : true))
        .map((f, index) => ({ ...f, order: index + 1 }))
    }
    if (index !== -1) {
      if (element.id && element.isSystemField) {
        data.alreadyUsedSystemControls = data.alreadyUsedSystemControls.filter(
          (f) => f !== element.inputType
        )
      }
      if (element.id && syncToServer) {
        return removeFormFieldApi(element)
          .then(() => {
            data.currentFormElements = updatedFields
            if (spacerField) {
              return removeFormFieldApi(spacerField, false)
            }
            return Promise.resolve()
          })
          .then(() => {
            return methods.reorderFields(index, sectionGuid, 0)
          })
      }
      data.currentFormElements = updatedFields
    }
  },

  /**
   * Remove from element from a given position
   * @param {string} guid
   * @param {undefined|string} sectionGuid
   * @param {boolean} syncToServer
   */
  removeFormElementWithGuid(guid, sectionGuid, syncToServer) {
    let index
    let element
    if (sectionGuid) {
      index = FindIndex(data.currentFormElements, { guid: sectionGuid })
      if (index !== -1) {
        element = data.currentFormElements[index].fields.find(
          (f) => f.guid === guid
        )
      }
    } else {
      index = FindIndex(data.currentFormElements, { guid: guid })
      if (index !== -1) {
        element = data.currentFormElements[index]
      }
    }
    if (index !== -1) {
      return methods.removeFormElement(element, sectionGuid, syncToServer)
    }
  },

  /**
   * Move element from one place to another
   * @param {number} newIndex
   * @param {number} oldIndex
   * @param {undefined|string} sectionGuid
   */
  moveElement(newIndex, oldIndex, sectionGuid) {
    const updatedFieldsMap = {}
    if (sectionGuid) {
      const sectionIndex = FindIndex(data.currentFormElements, {
        guid: sectionGuid,
      })
      if (sectionIndex !== -1) {
        const updatedField = {
          ...data.currentFormElements[sectionIndex],
          fields: move(
            data.currentFormElements[sectionIndex].fields,
            oldIndex,
            newIndex
          ).map((f, index) => ({ ...f, order: index + 1 })),
        }
        // now update the section field
        updatedField.fields
          .slice(Math.min(oldIndex, newIndex), Math.max(newIndex, oldIndex) + 1)
          .forEach((f) => {
            let transformed = transformFormFieldForServer(f)
            updatedFieldsMap[f.id] = {
              name: transformed.name,
              orderInField: transformed.orderInField,
              type: transformed.type,
            }
          })
        methods.updateSingleField(updatedField)
      }
    } else {
      data.currentFormElements = move(
        data.currentFormElements,
        oldIndex,
        newIndex
      ).map((f, index) => ({ ...f, order: index + 1 }))
      data.currentFormElements
        .slice(Math.min(oldIndex, newIndex), Math.max(newIndex, oldIndex) + 1)
        .forEach((f) => {
          let transformed = transformFormFieldForServer(f)
          updatedFieldsMap[f.id] = {
            name: transformed.name,
            orderInField: transformed.orderInField,
            type: transformed.type,
          }
        })
    }
    return updateFormFieldsApi(updatedFieldsMap).then((data) => {
      // @TODO update redux here for moduleName
    })
  },

  /**
   * Update the given field
   * @param {Object} field
   * @param {undefined|string} sectionGuid
   * @param {boolean} syncToServer
   */
  updateSingleField(field, sectionGuid, syncToServer) {
    let index
    let updatedField
    if (sectionGuid) {
      index = FindIndex(data.currentFormElements, { guid: sectionGuid })
      const fieldIndex = FindIndex(data.currentFormElements[index].fields, {
        guid: field.guid,
      })
      const sectionFields = data.currentFormElements[index].fields
      if (index !== -1) {
        updatedField = {
          ...data.currentFormElements[index],
          fields: [
            ...sectionFields.slice(0, fieldIndex),
            field,
            ...sectionFields.slice(fieldIndex + 1),
          ],
        }
      }
    } else {
      index = FindIndex(data.currentFormElements, { guid: field.guid })
      if (index !== -1) {
        updatedField = field
      }
    }
    if (index !== -1) {
      data.currentFormElements = [
        ...data.currentFormElements.slice(0, index),
        updatedField,
        ...data.currentFormElements.slice(index + 1),
      ]
    }
    if (syncToServer) {
      return updateFormFieldApi(transformFormFieldForServer(field))
    }
    return Promise.resolve()
  },

  /**
   * Duplicate given field
   * @param {Object} field
   * @param {String} sectionGuid
   */
  duplicateField(field, sectionGuid) {
    const f = {
      ...Omit(CloneDeep(field), ['id', 'guid', 'order']),
      title: `${__rootT('copy_of')} ${field.title}`,
      duplicate: true,
      guid: generateId(),
    }
    let index
    if (sectionGuid) {
      const sectionIndex = FindIndex(data.currentFormElements, {
        guid: sectionGuid,
      })
      index = FindIndex(data.currentFormElements[sectionIndex].fields, {
        guid: field.guid,
      })
    } else {
      index = FindIndex(data.currentFormElements, { guid: field.guid })
    }
    methods.setCurrentActiveItem(f, index + 1, sectionGuid)
  },

  /**
   * Set currently active hover item
   * @param {string} guid
   */
  setCurrentHoverActiveItemId(guid) {
    data.currentHoverActiveItemId = guid
  },

  /**
   * clear hover active item id
   */
  clearCurrentHoverActiveItemId() {
    data.currentHoverActiveItemId = null
  },
}
