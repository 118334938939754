<template>
  <FieldContainer
    :field="field"
    :section-id="sectionId"
    v-bind="containerAttrs"
    v-on="containerListeners"
  >
    <FlotoFormItem v-if="disabled" :label="label">
      <MRate
        :value="currentValue"
        :count="field.maxRating"
        :name="field.name"
        :allow-half="false"
        v-bind="attrs"
        disabled
        v-on="listeners"
      />
    </FlotoFormItem>
    <FlotoFormItem
      v-else
      :label="label"
      :rules="field.required ? 'required|nonzero' : undefined"
    >
      <MRate
        v-model="currentValue"
        :count="field.maxRating"
        :name="field.name"
        :allow-half="false"
        v-bind="attrs"
        :disabled="mode === 'builder' || field.attributes.systemManagedField"
        v-on="listeners"
      />
    </FlotoFormItem>
  </FieldContainer>
</template>

<script>
import FormField from '../../mixins/form-field'

export default {
  name: 'Rating',
  mixins: [FormField],
  props: {
    moduleName: { type: String, default: undefined },
  },
  computed: {
    label() {
      return this.moduleName === this.$constants.USER_SURVEY ||
        this.moduleName === this.$constants.USER_SURVEY_SCHEDULE
        ? this.field.question
        : this.field.title
    },
  },
}
</script>
