import {
  getRootPluaralTranslator,
  getModuleTranslator,
} from '@utils/get-module-translator'
import { generateId } from '@utils/id'
import moduleConfig from '../../config'
const __tc = getRootPluaralTranslator()
const __mtc = getModuleTranslator(moduleConfig.translationKey)

export const ComputerProperyFieldMap = () => ({
  computer_property_group: {
    title: __mtc('computer_property_group'),
    fields: [
      {
        key: generateId(),
        paramName: 'osName',
        name: `${__mtc('os')} ${__tc('name')}`,
        inputType: 'text',
      },
      {
        key: generateId(),
        paramName: 'osVersion',
        name: `${__mtc('os')} ${__mtc('version')}`,
        inputType: 'text',
      },
      {
        key: generateId(),
        paramName: 'servicePackName',
        name: `${__mtc('service_pack_name')}`,
        inputType: 'text',
      },
      {
        key: generateId(),
        paramName: 'osLicenceKey',
        name: `${__mtc('os')} ${__mtc('licence_key')}`,
        inputType: 'text',
      },
      {
        key: generateId(),
        paramName: 'osManufacturer',
        name: `${__mtc('os')} ${__mtc('manufacturer')}`,
        inputType: 'manufacturer',
      },
      {
        key: generateId(),
        paramName: 'osArchitecture',
        name: `${__mtc('os')} ${__mtc('architecture')}`,
        inputType: 'dropdown',
        contextOptionKey: 'osArchitectureOptions',
      },
      {
        key: generateId(),
        paramName: 'bootupState',
        name: `${__mtc('boot_status')}`,
        inputType: 'text',
      },
      {
        key: generateId(),
        paramName: 'memorySize',
        name: `${__mtc('memory_size')}`,
        inputType: 'bytes',
      },
      {
        key: generateId(),
        paramName: 'diskSize',
        name: `${__mtc('disk_size')}`,
        inputType: 'bytes',
      },
      {
        key: generateId(),
        paramName: 'cpuSpeed',
        name: `${__mtc('cpu_speed')}`,
        inputType: 'hertz',
      },
      {
        key: generateId(),
        paramName: 'cpuCoreCount',
        name: `${__mtc('cpu_core_count')}`,
        inputType: 'number',
      },
      {
        key: generateId(),
        paramName: 'partOfDomain',
        name: `${__mtc('part_of_domain')}`,
        inputType: 'boolean',
      },
      {
        key: generateId(),
        paramName: 'domainName',
        name: `${__mtc('domain_name')}`,
        inputType: 'text',
      },
      {
        key: generateId(),
        paramName: 'numberOfLogicalProcessors',
        name: `${__mtc('number_of_logical_processors')}`,
        inputType: 'number',
      },
      {
        key: generateId(),
        paramName: 'numberOfProcessors',
        name: `${__mtc('number_of_processors')}`,
        inputType: 'number',
      },
      {
        key: generateId(),
        paramName: 'pcSystemType',
        name: `${__mtc('pc_system_type')}`,
        inputType: 'dropdown',
        contextOptionKey: 'pcSystemTypeOptions',
      },
      {
        key: generateId(),
        paramName: 'lastLoggedInUser',
        name: `${__mtc('last_logged_in_user')}`,
        inputType: 'text',
      },
      {
        key: generateId(),
        paramName: 'activationStatus',
        name: `${__mtc('activation_status')}`,
        inputType: 'dropdown',
        contextOptionKey: 'activationStatusOptions',
      },
    ],
  },
})
