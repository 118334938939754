import { getModuleTranslator } from '@utils/get-module-translator'
import { generateId } from '@utils/id'
import moduleConfig from '../../config'

const __mtc = getModuleTranslator(moduleConfig.translationKey)

export const softwareComponentFieldMap = () => ({
  software_component: {
    canCreate: true,
    title: __mtc('software_component'),
    fields: [
      {
        key: generateId(),
        paramName: 'softwareName',
        name: __mtc('software_name'),
        inputType: 'text',
      },
      {
        key: generateId(),
        paramName: 'manufacturerId',
        name: __mtc('manufacturer'),
        inputType: 'manufacturer',
      },
      {
        key: generateId(),
        paramName: 'version',
        name: `${__mtc('version')}`,
        inputType: 'text',
      },
      {
        key: generateId(),
        paramName: 'installDate',
        name: __mtc('install_date'),
        inputType: 'date',
      },

      {
        key: generateId(),
        paramName: 'installLocation',
        name: `${__mtc('installed')} ${__mtc('location')}`,
        inputType: 'text',
      },
      {
        key: generateId(),
        paramName: 'installedsize',
        name: `${__mtc('installed')} ${__mtc('size')}`,
        inputType: 'bytes',
        unit: 'mb',
      },
      {
        key: generateId(),
        paramName: 'licensekey',
        name: `${__mtc('licence_key')}`,
        inputType: 'text',
      },
      {
        key: generateId(),
        paramName: 'licenseType',
        name: `${__mtc('licence')} ${__mtc('type')}`,
        inputType: 'dropdown',
        contextOptionKey: 'activationStatusOptions',
      },
      {
        key: generateId(),
        paramName: 'osCompatibility',
        name: `${__mtc('os')} ${__mtc('compatibility')}`,
        inputType: 'dropdown',
        contextOptionKey: 'osArchitectureOptions',
      },
      {
        key: generateId(),
        paramName: 'description',
        name: __mtc('description'),
        inputType: 'textarea',
      },
      {
        key: generateId(),
        paramName: 'uninstallCommand',
        name: `${__mtc('uninstall_command')}`,
        inputType: 'text',
      },
    ],
  },
})
