export default {
  props: {
    field: { type: Object, required: true },
    applyFormRules: { type: Boolean, default: false },
    fieldRulesState: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  computed: {
    isHide() {
      if (this.field.markAsHidden) {
        return true
      }
      if (!this.applyFormRules) {
        return false
      }
      if (this.field.required) {
        return false
      }
      const fieldKey = this.field.isSystemField
        ? this.field.paramName
        : this.field.id
      return (
        this.fieldRulesState[fieldKey] && this.fieldRulesState[fieldKey].hide
      )
    },
  },
}
